@block g-sysgallery__v1 {
	@element panel-holder {
		position: relative;
		grid-area: content;
	}

	@element grid {
		/* autoprefixer: off */
		display: grid;
		grid-template-columns: 1fr;
		gap: 2rem;
		
		@media (--min36) {
			grid-template-columns: 1fr 1fr;
		}
		@media (--min48) {
			gap: var(--m-base);
		}
		& + [class*="__grid"] > [class*="__heading"] {
			margin-top: var(--m-base);
			@media (--min48) {
				margin-top: calc(var(--m-base) * 1.5);
			}
		}

		@modifier col-3{
			@media (--min80) {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}

	@element heading {
		grid-column-start: 1;
		grid-column-end: -1;
		font-family: var(--font-base);
		font-size: var(--fz-20);
		font-weight: var(--fw-bold);
		margin-bottom: 1rem;
		color: hsl(var(--dark-purple));

		@media (--min48) {
			margin-bottom: calc((var(--m-base) / 2) * -.5);
		}

		& > span{ font-weight: var(--fw-base) }
	}
	
	@element image {
		border-radius: var(--radius);
	}
}
