@block l-icon {
	height: 100%;
	width: 100%;
	display: inline-block;
	fill: #000;
	fill: currentColor;
	display: inline-block;
	vertical-align: -0.1em;

	/* Icon sizes */

	@modifier xs {
		width: 1.2rem;
		height: 1.2rem;
	}

	@modifier sm {
		width: 1.6rem;
		height: 1.6rem;
	}

	@modifier md {
		width: 2rem;
		height: 2rem;
	}	
	@modifier lg {
		width: 2.6rem;
		height: 2.6rem;
	}	
	@modifier xl {
		width: 3rem;
		height: 3rem;
	}
	@modifier xxl {
		width: 6rem;
		height: 6rem;
	}	

	/* Rotate icons */

	@modifier rotate-90 {
		transform: rotate(90deg);
	}

	@modifier rotate-90--neg {
		transform: rotate(-90deg);
	}

	@modifier rotate-180 {
		transform: rotate(180deg);
	}

	@modifier flip-y {
		transform: scaleY(-1);
	}

	@modifier flip-x {
		transform: scaleX(-1);
	}

	/* Spinner */

	@modifier rotate-animation {
		animation: animationRotate360 1.4s cubic-bezier(0.79, 0.37, 0.17, 0.65)
			infinite;
	}

}

@block l-icon__tip {
    position: relative;
    width: 90px;
    left: -8px;
    height: 90px;
    
	@element blob {
		position: relative;
	    left: -2px;
	    color: hsl(var(--primary));

	    @modifier alt {
	    	color: hsl(var(--alt));
	    }
	}
	@element icon {
		color: #fff;
	    position: absolute;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    width: 40%;
	    height: 40%;
	    margin: auto;

	    @modifier specs {
	    	color: hsl(var(--c-body));
	    }
	}
}

@block l-icon__wrap {
	display: flex;
	align-items: center;

	@modifier align-start {
		align-items: flex-start;

		.l-icon {
			top: 6px;
		}
	}

	& > .l-icon {
		flex: 1 0 auto;
		position: relative;
		top: 1px;

		&:first-child {
			margin-left: 0rem;
			margin-right: 1.6rem;
		}

		&:last-child {
			margin-left: 1.6rem;
			margin-right: 0rem;
		}

		@media (--max48) {
			&:first-child {
				margin-right: 0.75rem;
			}

			&:last-child {
				margin-left: 0.75rem;
			}
		}
	}

	&.btn > .l-icon:first-child {
		margin-left: -1rem;
	}
	&.btn > .l-icon:last-child {
		margin-right: -1rem;
	}

	& > span:not(.l-icon) {
		width: 100%;
	}
}

.btn .l-icon__wrap > .l-icon {
	&:first-child {
		margin-left: -1rem;
	}

	&:last-child {
		margin-right: -1rem;
	}
}

@keyframes animationRotate360 {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
