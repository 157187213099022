@block c-offerte-button {
	grid-column: 2 / span 1;
	position: relative;
	z-index: 52;

	@media (--min64) {
		grid-column: 3 / span 1;
	}

	@element item {
		border: 0;
		background: hsl(var(--pink));
		padding: 2.2rem;
		color: hsl(var(--b-body));
		height: 100%;
		
		& > span {
			z-index: 10;
			position: relative;
		}
		&:focus {
			outline: 0;
		}
		/* &::after {
			content: "";
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: hsl(var(--secondary));
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			margin: auto;
			transform: scale(0);
			z-index: 0;
			opacity: 0;
		} */
		@media (--max64) {
			padding: 1.8rem;
		}
	}
	
	@element toaster {
		background: hsl(var(--c-body));
		width: 4rem;
		height: 4rem;
		z-index: 100;
		position: relative;
		border-radius: 50%;
		position: absolute 0 auto 0 -20%;
		margin: auto;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
		opacity: 0;
		
		@media (--max64) {
			right: 80%;
    		top: -5rem;
		}
	}

	@element counter {
		background: hsl(var(--dark));
		width: 2.5rem;
		height: 2.5rem;
		z-index: 101;
		position: relative;
		border-radius: 50%;
		position: absolute 1rem 1.5rem auto auto;
		margin: auto;
		color: #fff;
		font-size: var(--fz-14);
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
		opacity: 0;

		@media (--max64) {
			right: 1.5rem;
			top: .5rem;
		}
	}
}
