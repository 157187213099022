@block l-container {
	position: relative;
	width: 100%;
	z-index: var(--z-base);
	padding: 0 var(--m-outside);
	max-width: var(--max-width);
	margin: 0 auto;

	/* & + .l-container {
		margin-top: 8rem;

		@media (--max48) {
			margin-top: 4rem;
		}
	} */

	/* & + .l-content {
		margin-bottom: 4rem;
	} */

	@element background-blob {
		position: absolute auto 0 0 0;
	    width: 100vw;
	    height: 100%;
	    overflow: hidden;
	    z-index: -1;
	}
	@element blob-icon {
		width: 100%;
	    position: absolute auto auto -40% 20%;
	    @modifier light {
	    	color: hsl(var(--light));
	    }
	}

	@element title {
		margin-top: var(--m-xl);
		margin-bottom: 4rem;
	}
	@modifier 404 {
		margin-bottom: 12rem;
	}
}

@block l-content {
	padding: 0 var(--m-inside);
	width: 100%;
	position: relative;
	z-index: var(--z-base);

	@modifier cb{
		padding-top: var(--m-inside);
		padding-bottom: var(--m-inside);
	}

	&:not(.l-content--no-margin) + .l-content {
		margin-top: var(--m-outside);
	}

	@modifier sm{
		& + .l-content--sm {
			padding-top: 0;
		}
	}

	@modifier text{
		padding-top: var(--m-base);
		padding-bottom: var(--m-base);

		&:first-child{
			padding-top: var(--m-inside);
		}
		
	}
}

@block l-tiled-grid {
	/* autoprefixer grid: off */
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-auto-rows: auto;
	grid-auto-flow: dense;
	row-gap: var(--m-grid);
	column-gap: var(--m-base);
	
	@media (--min36) {
		grid-template-columns: repeat(2, 1fr);
	}

	@media (--min80) {
		grid-template-columns: repeat(3, 1fr);
	}


}


@block l-sidenav-grid {
	display: grid;
	grid-template-areas: "sidenav" "content";
	grid-template-columns: 1fr;
	grid-auto-rows: auto;
	gap: var(--m-grid);
	
	@media (--min64) {
		grid-template-areas: "sidenav content content content";
		grid-template-columns: repeat(4, 1fr);
	}
	
}


@block l-blob-holder {
	@element blob {
		position: absolute;
	    top: 0;
	    bottom: 0;
	    height: auto;
	    width: 400rem;
	    left: -170rem;
	    right: -170rem;
	    margin: auto;
	    @media (--min48) {
		    top: -35vw;
		    bottom: auto;
		    height: 100vw;
		    width: 260%;
		    left: -9999px;
		    right: -9999px;
		    margin: auto;
	    }
	}
}

.l-blob-holder__blob {
	fill: hsl(var(--light));
}

@block l-areagrid {
	@element grid {
		display: grid;
		grid-template-areas: "first" "second";
		grid-template-columns: minmax(0, 1fr);
		grid-gap: 6rem;
		@media (--min64) {
			grid-template-columns: repeat(3, minmax(0, 1fr));
			grid-template-areas: "first second second";
		}
		
		& > [class*="__col--first"] {
			grid-area: first;
		}
		& > [class*="__col--second"] {
			grid-area: second;
		}

		@modifier align-center {
			align-items: center;
		}
	}
}

@block l-table-grid {
	/* autoprefixer grid: off */
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	grid-gap: 6rem;
	@media (--min48) {
		/* autoprefixer grid: off */
		grid-template-columns: repeat( 2, minmax(0, 1fr));
	}
}

@block l-ofgrid {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;

	& + .l-ofgrid {
		margin-top: calc(var(--m-grid) * 2);
	}

	@media (--min48) {
		margin-left: calc(var(--m-grid) * -1);
		margin-right: calc(var(--m-grid) * -1);
	}

	& [class*="l-col-"] {
		flex: 1 0 100%;
		max-width: 100%;

		& + [class*="l-col-"] {
			margin-top: var(--m-grid);
			@media (--min48) {
				margin-top: 0;
			}
		}
		&[class*="l-col--md"] {
			@media (--min48) {
				&[class*="l-col--md-1-2"]:nth-of-type(n + 3) {
					margin-top: calc(var(--m-grid) * 2);
				}
				&[class*="l-col--md-1-3"]:nth-of-type(n + 4) {
					margin-top: calc(var(--m-grid) * 2);
				}
				&[class*="l-col--md-1-4"]:nth-of-type(n + 5) {
					margin-top: calc(var(--m-grid) * 2);
				}
			}
		}
		&[class*="l-col--lg"] {
			@media (--min64) {
				&[class*="l-col--lg-1-2"] {
					margin-top: 0;
					&:nth-of-type(n + 3) {
						margin-top: calc(var(--m-grid) * 2);
					}
				}
				&[class*="l-col--lg-1-3"] {
					margin-top: 0 !important;
					&:nth-of-type(n + 4) {
						margin-top: calc(var(--m-grid) * 2) !important;
					}
				}
				&[class*="l-col--lg-1-4"] {
					margin-top: 0 !important;
					&:nth-of-type(n + 5) {
						margin-top: calc(var(--m-grid) * 2) !important;
					}
				}
			}
		}

		@media (--min48) {
			padding-left: var(--m-grid);
			padding-right: var(--m-grid);
		}
	}
	@modifier sm {
		@media (--min48) {
			margin-left: calc((var(--m-grid) / 4) * -1);
			margin-right: calc((var(--m-grid) / 4) * -1);
		}
		& [class*="l-col-"] {
			& + [class*="l-col-"] {
				margin-top: var(--m-grid);
				@media (--min48) {
					margin-top: 0;
				}
			}
			&[class*="l-col--md"] {
				@media (--min48) {
					&[class*="l-col--md-1-2"]:nth-of-type(n + 3) {
						margin-top: calc(var(--m-grid) / 2);
					}
					&[class*="l-col--md-1-3"]:nth-of-type(n + 4) {
						margin-top: calc(var(--m-grid) / 2);
					}
				}
			}

			@media (--min48) {
				padding-left: calc(var(--m-grid) / 4);
				padding-right: calc(var(--m-grid) / 4);
			}
		}
	}
	@modifier center {
		align-items: center;
	}
	@media (--min48) {
		& .l-col--md-1-2 {
			flex: 0 0 50%;
			max-width: 50%;
		}
		& .l-col--md-1-3 {
			flex: 0 0 33.3334%;
			max-width: 33.3334%;
		}
		& .l-col--md-2-3 {
			flex: 0 0 66.6667%;
			max-width: 66.6667%;
		}
		& .l-col--md-1-4 {
			flex: 0 0 25%;
			max-width: 25%;
		}
		& .l-col--md-3-4 {
			flex: 0 0 75%;
			max-width: 75%;
		}
		& .l-col--md-1-1 {
			flex: 0 0 100%;
			max-width: 100%;
		}
	}
	@media (--min64) {
		& .l-col--lg-1-2 {
			flex: 0 0 50%;
			max-width: 50%;
		}
		& .l-col--lg-1-3 {
			flex: 0 0 33.3334%;
			max-width: 33.3334%;
		}
		& .l-col--lg-2-3 {
			flex: 0 0 66.6667%;
			max-width: 66.6667%;
		}
		& .l-col--lg-1-4 {
			flex: 0 0 25%;
			max-width: 25%;
		}
		& .l-col--lg-3-4 {
			flex: 0 0 75%;
			max-width: 75%;
		}
	}
}

@block l-cgrid {
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: auto; /* autoprefixer: ignore next */
	grid-gap: 2.4rem;
	padding: 0 var(--m-grid);
	width: 100%;
	height: 100%;

	@media (--min48) {
		/* autoprefixer: ignore next */
		column-gap: 8rem;
		row-gap: 6rem;
	}
	@media (--min64) {
		/* autoprefixer: ignore next */
		column-gap: 10rem;
	}
	@media (--min100) {
		/* autoprefixer: ignore next */
		column-gap: 12rem;
	}

	& + .l-grid {
		margin-top: 8rem;
	}
	@modifier force-placement {
		& .l-col--1-1 {
			grid-column: 1 / span 12;
		}

		@media (--min48) {
			& .l-col--md-1-2 {
				grid-column: span 6;
			}
			& .l-col--md-2-3 {
				grid-column: span 8;
			}
			& .l-col--md-1-3 {
				grid-column: span 4;
			}
			& .l-col--md-1-4 {
				grid-column: span 3;
			}
			& .l-col--md-3-4 {
				grid-column: span 9;
			}
			& .l-col--md-2-5 {
				grid-column: span 5;
			}
			& .l-col--md-3-5 {
				grid-column: span 7;
			}

			@media (--ie) {
				& .l-col--md-start-6 {
					grid-column-start: 6;
				}
				& .l-col--md-start-7 {
					grid-column-start: 7;
				}
				& .l-col--md-start-8 {
					grid-column-start: 8;
				}
			}
		}

		@media (--min64) {
			& .l-col--lg-1-2 {
				grid-column: span 6;
			}
			& .l-col--lg-2-3 {
				grid-column: span 8;
			}
			& .l-col--lg-1-3 {
				grid-column: span 4;
			}
			& .l-col--lg-1-4 {
				grid-column: span 3;
			}
			& .l-col--lg-3-4 {
				grid-column: span 9;
			}
			& .l-col--lg-2-5 {
				grid-column: span 5;
			}
			& .l-col--lg-3-5 {
				grid-column: span 7;
			}

			@media (--ie) {
				& .l-col--lg-start-9 {
					grid-column-start: 9;
				}
			}
		}
	}

	@element parent {
		height: 100%;
		margin: 0 calc(var(--m-grid) * -1);
	}

	@modifier align-center {
		align-items: center;
	}

	@modifier fallback-to-flex {
		@media (--ie) {
			display: flex;
			flex-wrap: wrap;
			margin-left: -3rem;
			margin-right: -3rem;
			width: calc(100% + 6rem);

			& > [class*="l-col--"] {
				flex: 0 0 100%;
				max-width: 100%;
				padding-left: 3rem;
				padding-right: 3rem;
				margin-left: 0 !important;
				margin-right: 0 !important;
				@media (--min64) {
					&.l-col--lg-1-3 {
						flex: 0 0 33.3334%;
						max-width: 33.3334%;
					}
				}
				@media (--min48) {
					&.l-col--md-1-3 {
						flex: 0 0 33.3334%;
						max-width: 33.3334%;
					}
				}
			}
		}
	}

	@media (--ie) {
		& > *:first-child:not(:last-child) {
			margin-right: 6rem;
		}
		& > *:not(:first-child):not(:last-child) {
			margin-left: 3rem;
			margin-right: 3rem;
		}
		& > *:last-child:not(:first-child) {
			margin-left: 6rem;
		}
		&[class*="flip"] > *:first-child:not(:last-child) {
			margin-right: 0;
			margin-left: 6rem;
		}
		&[class*="flip"] > *:last-child:not(:first-child) {
			margin-left: 0;
			margin-right: 6rem;
		}
	}
}

.l-articlenumber__container {
	position: absolute;
    bottom: -12rem;
    margin: 0;
    & > * {
    	display: flex;
	    flex-direction: row-reverse;
	    padding: 2rem 2rem;
	    font-size: 14px;
	    font-weight: 700;
		color: #000;
		opacity: .6;
    }
}


@media (--min48) {
	.l-col--centertext {
		display: flex;
		flex-direction: column;
		align-items: center;

		& > * {
			max-width: 60%;
			text-align: center;
			margin: auto;
		}

		& > :--heading {
			max-width: 45%;
		}
	}
}

html[data-theme="lightshow"] {
	& .l-articlenumber__container span {
		color: #FFF;
	}
}
