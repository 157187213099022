@block c-multibutton {
	
	@element holder {
		display: grid;
		grid-template-areas: "remove text add";
		grid-template-columns: 6rem minmax(3rem, 1fr) 6rem;
		grid-column-gap: 1rem;
		height: 6rem;
		border-radius: 6rem;
		background: hsl(var(--white));
		@modifier small {
			height: 4.5rem;
			grid-template-areas: "remove text add";
			grid-template-columns: 4.5rem minmax(3rem, 1fr) 4.5rem;
		}
		& > * {
			justify-self: center;
			align-self: center;
		}
	}
	@element button {
		height: 100%;
		width: 100%;
    	border: 4px solid hsl(var(--white));
    	touch-action: manipulation;

    	/* @modifier dark {
    		border-color: hsl(var(--c-body));
    	} */
	}
	@element remove {
		grid-area: remove;

	}
	@element add {
		grid-area: add;
	}
	@element text {
		grid-area: text;
		overflow: hidden;
	    text-overflow: ellipsis;
	    width: 100%;
	    text-align: center;
	    width: 3rem;
	    height: 90%;
	    border: 0;
	    font-size: var(--fz-18);
	    background: transparent;
	    font-weight: var(--fw-bold);
	    border-bottom: 2px solid transparent;
	    &:focus {
	    	outline: 0;
	    	border-bottom-color: rgba(0,0,0,0.05);
	    }
	}
	@element icon {

	}
}

html[data-theme="lightshow"] {
	& .c-multibutton__holder {
		background: #EBF4F9;
		color: hsl(var(--b-body));
	}
	& .c-multibutton__button {
		border-color: #EBF4F9;
		color: hsl(var(--b-body));
	}
	
}
