@block cb-image-compare{
    --wrapper-width: 120rem;
    position: relative;
    /* min-height: 600px; */
    margin: 0 auto;
    width: 100%;
    max-width: var(--wrapper-width);
    aspect-ratio: 16/9;

    @element image{
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;

        & > [class*="l-image__parent"]{
            display: block;
            /* vertical-align: middle; */
            width: 100vw;
            height: 100%;
            max-width: var(--wrapper-width);
        }

        & [class*="l-image__item"]{
            /* width: auto; */
        }
    }

    @element slider{
        position: absolute;
        z-index: 9;
        cursor: ew-resize;
        /*set the appearance of the slider:*/
        width: 40px;
        height: 40px;
        background-color: hsl(var(--white));
        border-radius: 50%;

        &:after{
            content: '';
            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0OC42MTUiIGhlaWdodD0iMjQuMzA1IiB4bWxuczp2PSJodHRwczovL3ZlY3RhLmlvL25hbm8iPjxwYXRoIGQ9Ik0zMy42MTYgMi44NDRsNy4yOTQgNy4zSDcuNzA1bDcuMjk0LTcuM0wxMi4xNTUgMCAwIDEyLjE1MmwxMi4xNTUgMTIuMTUzIDIuODQ0LTIuODQ0LTcuMjk0LTcuM2gzMy4yMDZsLTcuMjk0IDcuMyAyLjg0NCAyLjg0NCAxMi4xNTQtMTIuMTUzTDM2LjQ2MSAweiIgZmlsbD0iIzBjMDQxYyIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+);
            position: absolute;
            background-size: contain;
            background-repeat: no-repeat;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            width: 2.5rem;
            height: 1.3rem;
        }

    }
}