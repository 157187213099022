@block c-footer {
	background-color: hsl(var(--light-gray)); 
	margin-top: 8rem;

	@element container {
		grid-area: mpFooter;
		display: flex;
		flex-direction: column;
		padding: 4rem var(--m-inside);
		color: hsl(var(--c-body));
		& > * + * {
			margin-top: 3rem;
		}
		/* &::before {
			content: "";
		    position: absolute 0 -9999px 0 -9999px;
		    position: absolute;
		    height: 100%;
		    width: 100%;
		    z-index: -1;
		   	border-top: 1px solid rgba(206,216,220, 0.25);
		} */
		
		@media (--min48) {
			padding: 6rem 0;
		}
	}
	@element grid {
		display: flex;
		& > * {
			flex: 0 0 auto;
		}
		@supports(display:grid) {
			/* autoprefixer grid: off */
			display: grid; 
			grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
			grid-template-columns: repeat(auto-fit, minmax(min(30rem, 100%), 1fr));
			grid-gap: 6rem;
		}
	}
	@element logo {
		& > img {
			width: 6rem;
			height: 6rem;
			object-fit: contain;
		}
	}
	@element col {
		& h4, &.h4 {
			font-size: var(--fz-24);
			font-weight: var(--fw-bold);
			color: hsl(var(--dark-purple));
			margin-bottom: 2.8rem;
		}
 	}
 	@element nav {
 		list-style: none;
 		padding: 0;
	    margin-top: -1rem;

	    @modifier split {
	 		column-count: 2;
	    }
 	}
	@element full {
		@media (--min48) {
			text-align: center;
		}
	}
}

@block c-footer-nav {
	@element link {
		color: inherit;
		padding: 1rem 0;
		display: inline-block;
		text-decoration: none;
		font-weight: var(--fw-bold);
		transition: all .2s ease;
		&:hover, &:focus {
			color: hsl(var(--c-body-h), calc(var(--c-body-s) - 5%), calc(var(--c-body-l) - 15%));
			text-decoration: underline;
		}
	}
}