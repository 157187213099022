@block c-combi-block{
    display: grid;
    grid-template-areas: "text"
                         "image"
                         "columns";
    gap: var(--m-base);
    background-color: hsl(var(--secondary));
    grid-auto-rows: auto;

    @media (--max64) {
        /* autoprefixer: off */
        grid-template-columns: 1fr;
    }

    @media (--min64) {
        /* autoprefixer: off */
        grid-auto-columns: 1fr;
        grid-template-areas: "text image"
                         "columns columns";

        &[class*="--sfeerconcepten"]{
            row-gap: 0;
            grid-template-areas: "image text"
                                "columns columns";
        }
        &[class*="--verhuur"]{
            grid-template-areas: "text text image"
            "columns columns columns";
        }
    }

    @modifier sfeerconcepten{
        @media (--min64){
            row-gap: 0;
            grid-template-areas: "image text"
                                "columns columns";
        }
    }

    @modifier verhuur{
        @media (--min64){
            grid-template-areas: "text text image"
            "columns columns columns";
        }
    }

    &:before{
        content: "";
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMTYxLjU2MiIgaGVpZ2h0PSIxMTYxLjQ4NCIgeG1sbnM6dj0iaHR0cHM6Ly92ZWN0YS5pby9uYW5vIj48cGF0aCBkPSJNMTQ2LjY0NSA2MzEuMjY4YzEzLjkzNyA0MC40NzYgMzAuMjk2IDgwLjY3NyA0Ny4yNTQgMTIxLjc3NiA0Ny41NTYgMTE1Ljc5NiAxMDEuNDU3IDI0Ny4wNDMgMTg3LjY2MSAyODUuNzY3IDcyLjQ1NiAzMi42ODQgMTg0LjgxMiAxMS4yMDEgMzQzLjQ4Ni02NS42NzUgMTgwLjkxOS04Ny42NTQgMjgwLjcwMS0xNzguMTI2IDMwNS4wNzktMjc2LjY2MyAxMS41NjYtNDYuMjcxIDcuMjUyLTEwMS4wMjQtMTIuNDgzLTE1OC4zMzctMjYuOTU3LTc4LjI4OS04MS40NzgtMTU3LjY5MS0xNDkuNTMzLTIxNy44MTEtMTEzLjY0OC05OS45ODQtMjY1LjQ5Mi0xNDguMDg3LTQxNi42MjgtMTMxLjk3Ni0xNTIuNDIxIDE2LjA3MS0yODYuMTIxIDk1LjMzOC0zMjUuMTY1IDE5Mi44NDMtMjQuNTUzIDYwLjgxNi0xOC4yODIgMTM3Ljk0MiAyMC4zMjkgMjUwLjA3N20tMjguMzY2IDkuNzY3Qzg1LjA5MyA1NDQuNjUyIDY2LjQ1MSA0NDkuMzQgOTguNDk1IDM2OS45NjJjNDUuNDc3LTExMy41NjcgMTk0LjI3MS0xOTUuMDQ1IDM0OS44MzgtMjExLjQ0NyAxNTUuMjYxLTE2LjU1IDMxNy41MjEgMzEuODkyIDQzOS41OTYgMTM5LjI4MyAxMjEuOTIxIDEwNy43MDEgMjA0LjE4OCAyNzQuNDIxIDE3MS4zMDQgNDA1Ljk0NS0zMi41NzUgMTMxLjY3OS0xODAuMzAyIDIyOC4xNjctMzIxLjEgMjk2LjM4N3MtMjc1LjE0NCAxMDguMzI1LTM2OC45MDcgNjYuMDI1Yy05My45OTEtNDIuMjIxLTE0Ny41NTctMTY2LjUzMy0yMDMuMDc5LTMwMS43MTUtMTYuNzI5LTQwLjU0NC0zMy42MzctODIuMDc3LTQ3Ljg2OC0xMjMuNDA2eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
        background-position: 100% -40%;
        background-repeat: no-repeat;
        background-size: 115%;
        position: absolute;
        aspect-ratio: 1/1;
        height: 100%;
        width: auto;
        min-width: 50%;
        max-width: 100%;
        fill: #fff;
        left: 0;
        bottom: 0;
        opacity: .15;
        z-index: 0;
    }

    @element text{
        grid-area: text;
        min-width: 0;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (--max80) {
            margin-bototm: 4rem;
        }

    }

    @element image{
        /* autoprefixer: off */
        grid-area: image;

        @modifier extended{
            
            @media (--max64){
                grid-area: text;
                width: 50%;
                position: absolute;
                top: calc(0px - var(--m-large));
                right: calc(0px - var(--m-inside));
                max-width: 320px;
            }

            @media(--min64){
                margin-top: -8rem;
            }

            @media(--min80){
                margin-top: -12rem;
            }
        }

        @modifier clipped{
            clip-path: url(#blobOne-flip);
            
            @media (--min80) {
                align-self: end;
                clip-path: url(#image-blob);
            }   

            @media (--max80){
                align-self: start;
                & > [class*="l-image__parent"]::before{
                    aspect-ratio: 1/1;
                }
            }
        }
    }

    @element columns{
        /* autoprefixer: off */
        grid-area: columns;
        display: flex;
        gap: var(--m-base);

        @media (--max48) {
            flex-direction: column;
        }
    }

    @element column{
        flex: 1 1 33%;        
    }
}