@block c-promoblok{
    display: grid;
    grid-template-areas: "image"
                         "content";
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr auto;

    @media (--min48) {
        grid-template-areas: "image content";
        grid-template-columns: 1fr 2fr;
        grid-auto-rows: auto;
    }

    @media (--min80) {
        grid-template-columns: 2fr 3fr;
    }

    @element image{
        grid-area: image;
        background: #000;
    }

    @element content{
        padding: var(--m-base);
        grid-area: content;
        background: hsl(var(--secondary));
        color: hsl(var(--themed-text));
        display: flex;
        justify-content: center;
        flex-direction: column;

        & > .btn-holder{
            margin-top: 0.5rem;
        }
    }

}