@block c-faq{
    @element wrapper{
        padding: 0;
        list-style: none;
        max-width: 100rem;
    }
    @element item{
        display: flex;
        flex-direction: column;
        padding: 3rem 0;
        
        &:not(:first-child){
            border-top: 1px solid hsla(var(--black-purple), 0.10);
        }

        &:hover{
            cursor: pointer;
            
            & > [class*="question"]{
                color: hsl(var(--primary));

            }
        }

        &.active{
            & > [class*="question"]{
                color: hsl(var(--primary));            
             
                & > .minus{ display: inline-block; }
                & > .plus{ display: none; }
            }

            & > [class*="answer"]{
                margin-top: 3rem;
                max-height: 30rem;
            }
        }
    }

    @element question{
        color: hsl(var(--black-purple));
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: color 0.2s ease-out;
        
        & > .minus{ display: none; }

    }

    @element answer{
        overflow: hidden;
        max-height: 0;
        margin-top: 0;
        transition: max-height 0.2s ease-in-out, margin 0.2s ease-in-out;

        & > *{
            margin: 0;
        }

    }
}