@block modal {


	@element wrapper{
		position: fixed;
		z-index: var(--z-modal);
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;

      
        &[aria-hidden="true"] {
		    display: none;
	    }
        &[aria-hidden="false"] {
		    display: block;
	    } 
	}

	@element bg{
		background: hsl(var(--black-purple));
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 0;
		position: fixed;
		transition: opacity .3s ease-out;
	}

	@element item {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-25%) scale(0.8);
		background: hsl(var(--white));
		opacity: 0;
		transition: all .2s cubic-bezier(.25,.46,.45,.94); 
		width: 80rem;
		max-width: 90%;
	}

	@element content{
		padding: var(--m-base);
	}

	@element close{
		color: #fff;
		position: absolute;
		top: 0;
		right: 0;
		text-decoration: none;
		text-transform: uppercase;
		font-size: 1.5rem;
		font-weight: 600;
		transform: translateY(-100%);
		padding: .4rem;
		border: 2px dotted transparent;
		transition: border .2s;
		background: transparent;
	
		&:focus{
			outline: 2px dashed hsl(var(--pink));
			outline-offset: 2px;
		}
	}

	@element close-icon{
		margin-left: .2rem;
		fill: #fff;
		display: inline-block;
		width: 1.2rem;
		height: 1.2rem;
		color: inherit;
	}


}


.modal-show{
	& > [class*="__bg"] {
    	opacity: 0.8;
   	}
	& > [class*="__item"] {
	    opacity: 1;
   	    transform: translateX(-50%) translateY(-50%) scale(1); 
    } 
} 
