@block c-breadcrumbs {
	@element container {
		z-index: 11;
		font-size: var(--fz-13);
		display: flex;
		align-items: center;
		overflow: hidden;
		& > a {
			display: none;
			@media (--max64) {
				display: flex;
				margin-right: 2rem;
			}
		}
		@media (--max64) {
			display: none;
		}
	}
	@element list {
		display: flex;
		list-style: none;
    	padding: 0;
    	overflow: hidden;
	}
	@element item {
		white-space: nowrap;
		& + [class*="item"] {
			&::before {
				content:"\25B8";
				margin-right: 1.1rem;
				font-weight: var(--fw-bold);
				color: hsl(var(--themed-text));
			}
			margin-left: 1.5rem;
		}
		&:last-child {
			overflow: hidden;
		    text-overflow: ellipsis;

		}
		&:last-child a {pointer-events: none; text-decoration: none; opacity: .6;}
	}
	@element link {
		color: hsl(var(--themed-text));
		font-weight: var(--fw-medium);
		text-transform: uppercase;
		transition: color .1s ease-in-out;
		letter-spacing: .15rem;
		&:hover, &:focus {
			color: hsl(var(--primary));
			text-decoration: none;
		}

		&:focus {
			outline: 0;
		}

		&:focus-visible {
			outline: 2px dashed hsl(var(--primary));
			outline-offset: 4px;
		}
	}
}
