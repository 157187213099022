@block l-item {

	& > * {margin: 0; } & > * + * {margin-top: 2.6rem;}
	@modifier xs { & > * + * {margin-top: 1rem} }
	@modifier sm { & > * + * {margin-top: 2rem;} }
	@modifier md { & > * + * {margin-top: 4rem;} }
	@modifier lg { & > * + * {margin-top: var(--m-large);} }

}

.l-item > style, .l-item > script {display: none;}