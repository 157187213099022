@block c-nav {
	@element container {
		/* max-width: 12rem; */

		@media (--max64) {
			visibility: hidden;
			opacity: 0;
			position: fixed 0 0 0 100%;
			height: 100%;
			width: 100%;
			background: hsl(var(--c-body));
			color: hsl(var(--b-body));
			transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
			z-index: 50;
			/* max-width: inherit; */
		}

		@media (--min64) {
			grid-column: 2 / span 1; 
		}

		&.s-open {
			left: 0;
    		opacity: 1;
    		visibility: visible;
		}
	}
	@element list {
		display: flex;
		flex-direction: column;
		padding: 0;
		list-style: none;
		margin: 0;
		padding-top: 12rem;

		@media (--min64) {
			flex-direction: row;
			padding: 0;
			align-items: flex-end;
			height: 100%;

		
			& > * + * {
				margin-top: 2.6rem;
			}
		}
	}	
	@element item {
		@media (--max64) {
	    	border-bottom: 3px solid rgba(0,0,0,0.1);
	    }
	}
	@element link {
		display: grid;
		grid-template-areas: "icon text";
		grid-template-rows: auto;
	    grid-template-columns: auto;
		text-decoration: none;
		text-transform: uppercase;
		font-size: var(--fz-11);
		font-family: var(--font-base);
		letter-spacing: .1rem;
		color: inherit;
		font-weight: 700;
		max-width: 100%;
	    padding: 2rem;
	    text-align: center;
	    appearance: none;
	    background: transparent;
	    border: 0;
	    margin: 0 auto;
	    width: 100%;
	    grid-gap: .3rem;
	    &:focus {
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed hsl(var(--secondary));
			outline-offset: -2px;
		}

	    @modifier nopadding {
	    	padding: 0;
	    }

	    @modifier stay-split {
			grid-template-areas: "icon" "text";
			justify-content: center;
	    }	
		@media (--min64) {
			grid-row-gap: 1rem;
			padding: 2rem 1rem;
			font-size: var(--fz-12);
			grid-template-rows: 1fr auto;
			grid-template-columns: minmax(0, 1fr);
			grid-template-areas: "icon" "text";
			transition: all 0.15s ease-out;

			&:not([class*="--stay-split"]){
				border-bottom: 2px solid transparent;
				&:hover {
					border-bottom: 2px solid hsl(var(--primary));
					color: hsl(var(--primary));
				}
			}
		}
		@media (--max64) {
			grid-column-gap: 3rem;
			grid-template-columns: min-content;
		}

		@media (--min72) {
			padding: 2rem 1.25rem;
			font-size: var(--fz-13);
		}

		@media (--min80) {
			padding: 2rem 1.5rem;
			font-size: var(--fz-14);
		}

		& > [class*="__text"] {
			grid-area: text;
			align-self: center;
			justify-self: start;
			display: inline-block;
			&:first-child:last-child {
				grid-area: 1 / 1 / span 2 / span 2;
				align-self: center;
			}
			@media (--min64) {
				justify-self: center;
				align-self: start;
			}
		}

		& > [class*="__icon"] {
			grid-area: icon;
			justify-self: center;
			align-self: end;
		}

		&:not([class*="--stay-split"]) >  [class*="__icon"]{
			@media (--min64) {
				display: none;
			}
		}
	}
	@element button {
		padding: 0;
		appearance: none;
		width: 100%;
	    border: 0;
	    outline: 0;
	    background: transparent;
	    font-family: var(--font-base);
	    
	    @media (--max64) {
	    	height: 100%;
	    }
	    @media (--min64) {
	    	display: none;
	    }
	    &:focus {
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed hsl(var(--secondary));
			outline-offset: -2px;
		}

	    @modifier back {
	    	height: 8rem;
	    	width: 8rem;
	    	background: hsl(var(--c-body));
	    	color: hsl(var(--b-body));
	    	border-radius: 50%;
	    	overflow: hidden;
	    	align-items: center;
	    	justify-content: center;
		    &:focus {
				outline: 0;
			}
			&:focus-visible {
				outline: 2px dashed hsl(var(--c-body));
				outline-offset: -2px;
			}
	    }

	}
	@element text {		
		overflow-wrap: normal;
		max-width: 100%;

		@media (--max64) {
			font-size: 1.2em;
		}
	}
	
	@element icon {
		width: 3rem;
		height: 3rem;

		@media (--max64) {
			width: 2rem;
			height: 2rem;
		}
	}
}