@block g-tekst-en-blob__v1 {
	@element grid {
		display: grid;
		grid-template-areas: "text" "picture";
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-gap: 6rem;

		& > [class*="__col--text"] {
			align-self: center;
			grid-area: text;
		}
		& > [class*="__col--picture"] {
			align-self: center;
			grid-area: picture;
		}
		& picture {
    		clip-path: url(#blobOne);
		}
		@media (--min48) {
			grid-template-areas: "text picture";
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-gap: 12rem;
		}
	}
}

/* V2 moet gedupliceerd staan, anders snapt IE niets van het grid. */
@block g-tekst-en-blob__v2 {
	@element grid {
		display: grid;
		grid-template-areas: "picture" "text";
		grid-template-columns: repeat(1, minmax(0, 1fr));
		grid-gap: 4rem;

		& > [class*="__col--text"] {
			grid-area: text;
			align-self: center;
		}
		& > [class*="__col--picture"] {
			grid-area: picture;
			align-self: center;
		}
		& picture {
    		clip-path: url(#blobOne-flip);
		}
		@media(--min48) {
			grid-template-areas: "picture text";
			grid-template-columns: repeat(2, minmax(0, 1fr));
			grid-gap: 12rem;
		}
	}
}

/* Styles die voor beide blokken gelden */
@block g-tekst-en-blob__v1, g-tekst-en-blob__v2 {
	@element col {
		& > :--heading {
			color: hsl(var(--alt));
		}
		& > p, & > ul, & > ol {
			line-height: 1.9;
			font-weight: 400;
		}
		& > .btn {
			margin-top: 3rem;
		}
	}
}