@block c-panelswitch {
	@element panel-holder {
		position: relative;
		grid-area: content;
	}
	@element panel {
		position: relative;
		z-index: 10;
		&[aria-hidden="true"] {
			display: none;
		}
		&.is-removing {
			position: absolute;
			top: 0;
			left: var(--m-grid);
			right: var(--m-grid);
			z-index: 0;
		}
	}
}