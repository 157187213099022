@import "base/normalize.css";

@import "base/variables.css";

@import "plugins/slick.css";

@import "layout/grid.css";
@import "layout/image.css";
@import "layout/video.css";
@import "layout/item.css";

@import "elements/base.css";
@import "elements/button.css";
@import "elements/type.css";
@import "elements/icons.css";
@import "elements/form.css";
@import "elements/lists.css";

@import "components/cookies.css";
@import "components/multibutton.css";
@import "components/homepage.css";
@import "components/linkelem.css";
@import "components/header.css";
@import "components/footer.css";
@import "components/nav.css";
@import "components/breadcrumbs.css";
@import "components/info.css";
@import "components/product-tile.css";
@import "components/blog-tile.css";
@import "components/overlay.css";
@import "components/scrollwindow.css";
@import "components/offerteblob.css";
@import "components/offerte-button.css";
@import "components/offerte-template.css";
@import "components/usp-block.css";
@import "components/category.css";
@import "components/imageoverlay.css";
@import "components/sidenav.css";
@import "components/panelswitch.css";
@import "components/specs.css";
@import "components/pplx-lightbox.css";
@import "components/combi-block.css";
@import "components/events.css";
@import "components/hero-banner.css";
@import "components/anchor-menu.css";
@import "components/faq.css";
@import "components/promoblok.css";
@import "components/slider.css";
@import "components/image-compare.css";
@import "components/tile.css";
@import "components/filter-menu.css";
@import "components/pricing.css";
@import "components/end-pagination.css";
@import "components/pagination.css";
@import "components/modal.css";

@import "grid/header-content.css";
@import "grid/header-rental.css";
@import "grid/tekst-en-afbeelding.css";
@import "grid/tekst-en-blob.css";
@import "grid/tekst-groot.css";
@import "grid/blogitems.css";
@import "grid/cta.css";
@import "grid/sysgallery.css";

@font-face {
	font-family: 'Outfit';
	src: url('/Assets/Fonts/Outfit-VariableFont_wght.ttf') format("truetype-variations");
	font-style: normal;
	font-weight: 1 999;
}

:root {
	/* Orange  * #F05820 */
	--white: 0, 0%, 100%;	

	/* Yellow  * #F9B716 */
	--yellow:  43, 95%, 53%;
	/* Yellow -- Light  * #fef5dc */
	--yellow-light:  44, 94%, 93%;

	/* Orange  * #F3791D */
	--orange: 26, 90%, 53%;	
	/* Orange -- Light  * #feebdd */
	--orange-light:  25, 94%, 93%;

	/* Orange colored red  * #F05820 */	
	--orange-red: 16, 87%, 53%;
	/* Orange-red -- Light  * #fde6dd */
	--orange-red-light:  17, 89%, 93%;

	/* Black Purple  * #0C041C */
	--black-purple: 260, 75%, 6%;
	
	/* Default kleur thema */
	--primary: var(--orange-red);
	--secondary: var(--yellow);
	--themed-text: var(--black-purple);
	
	--primary--alt: var(--orange-red);
	--secondary--alt: var(--black-purple);
	--themed-text--alt: var(--white);

}

:root.kleur-profiel__sfeer-concept--1{
	--primary: var(--orange-red);
	--secondary: var(--yellow);
	--themed-text: var(--black-purple);
}


:root.kleur-profiel__sfeer-concept--2 {
	/* --primary: var(--white); */
	--primary: var(--orange);
	--secondary: var(--orange);
	--themed-text: var(--black-purple);
	
	--primary--alt: var(--orange);

	& [class*="c-hero-banner__heading"], 
    & [class*="c-combi-block"] ,
    & [class*="c-breadcrumbs__link"] {
        --primary: var(--white);
    }
}

:root.kleur-profiel__sfeer-concept--3{
	--primary: var(--yellow);
	--secondary: var(--orange-red);
	--themed-text: var(--black-purple);
	
	--primary--alt: var(--orange-red);
}


:root.kleur-profiel__sfeer-concept--4{
	--primary: var(--yellow);
	--secondary: var(--black-purple);
	--themed-text: var(--white);
	
	--primary--alt: var(--white);
	--secondary--alt: var(--yellow);
	--themed-text--alt: var(--black-purple);
}

:root.kleur-profiel__sfeer-concept--5{
	--primary: var(--yellow);
	--secondary: var(--black-purple);
	--themed-text: var(--white);
	
	--primary--alt: var(--white);
	--secondary--alt: var(--yellow);
	--themed-text--alt: var(--black-purple);
}


:root.kleur-profiel__contentpagina--1{
	--primary: var(--yellow);
	--secondary: var(--yellow-light);
	--themed-text: var(--black-purple);
	
	--primary--alt: var(--yellow);
}


:root.kleur-profiel__contentpagina--2{
	--primary: var(--orange);
	--secondary: var(--orange-light);
	--themed-text: var(--black-purple);
	
	--primary--alt: var(--orange);
}


:root.kleur-profiel__contentpagina--3{
	--primary: var(--orange-red);
	--secondary: var(--orange-red-light);
	--themed-text: var(--black-purple);
	
	--primary--alt: var(--orange-red);
}
