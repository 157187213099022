@block c-offerte-template {
	@element grid {
		grid-template-areas: "content form";
		grid-template-columns: repeat(2, minmax(0, 1fr));
		grid-gap: 16rem;
		& > [class*="__content"] {
			grid-area: content;
		}
		& > [class*="__form"] {
			grid-area: form;
		}
	}

	@element content{
		& > h1{ color: hsl(var(--pink)); }

		@media (--max64) {
			padding-right: 2rem;	
		}
	}

	@element item-lijst {
		margin-top: 6rem;
	}
	@element list {
		margin: 2rem 0;
		padding: 0;
	}
	@element date {
		flex-flow: row wrap;

		@media (--min64) {
			flex-flow: row nowrap;

			& div:first-child {
				margin-right: 2rem;
			}
		}
	}
	@element placeholder {
		font-size: var(--fz-18);
		list-style: none;
		padding-left: 0;
		& a {
			text-decoration: none;
			position: relative;
			padding-left: 6rem;
			border: 1px solid hsl(var(--secondary));
			border-radius: 5rem;
			height: 5rem;
			display: flex;
			align-items: center;
			font-weight: 600;
			overflow: hidden;
			transition: all .5s ease-in-out;
			&:before {
				content: '+';
				width: 5rem;
				height: 5rem;
				background: hsl(var(--secondary));
				position: absolute;
				left: -.1rem;
				top: -.1rem;
				border-radius: 5rem;
				display: flex;
				align-items: center;
				justify-content: center;
				font-weight: normal;
				font-size: 3.5rem;
				z-index: -1;
				transition: all .5s ease-in-out;
			}
			&:hover {
				padding-left: 3rem;
				&:before {
					padding-left: calc(100% - 3rem + .1rem);
					padding-right: 3rem;
				}
			}
			& + a {
				margin-top: 2rem !important;
			}
		}
		& li:last-child {
			margin-top: 2rem;
		}
	}
	@element list-item {
		display: grid;
		grid-template-areas: "image details buttons delete";
		grid-auto-columns: 12rem 1fr auto auto;
		grid-auto-rows: auto;
		align-items: center;
		font-weight: var(--fw-bold);
		background-color: hsl(var(--white));
		border-radius: 10px;
		overflow: hidden;
		color: hsl(var(--black-purple));
		column-gap: 2rem;
		min-height: 10rem;

		& > [class*="c-multibutton__holder"] {
			grid-area: buttons;
			justify-self: start;
		}
		& + [class*="__list-item"] {
			margin-top: 2rem;
		}
		
		@modifier disabled {
			& button {display: none;}
		}

		@media (--max80) {
			grid-template-areas: "image details delete"
								 "image buttons delete";
			grid-auto-rows: 1fr auto;
			
			& > [class*="c-multibutton__holder"] {
				margin-bottom: 1rem;
			}

			& > [class*="details"] {
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
		}

		@media (--max30) {
			grid-template-areas: "details delete"
								 "buttons delete";
			grid-auto-columns: 1fr auto;
			padding-left: 2rem;
			
			& > [class*="image"] {
				display: none !important
			}	
		}
	}
	@element item-image {
		max-width: 13rem;
		height: 100%;
		grid-area: image;
	}
	@element item-details {
		grid-area: details;
		display: flex;
		flex-direction: column;
		flex-shrink: 1;
		overflow: hidden;
		min-width: 200px;
	}
	@element item-type {
		flex: 1 1 auto;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 1.6rem;
		font-weight: var(--fw-base);
		text-transform: capitalize;
	}
	@element item-name {
		flex: 1 1 auto;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: hsl(var(--pink));
		font-size: 2.4rem;
	}
	@element item-price {
		flex: 0 0 auto;
	}
	@element totaal-holder {
		display: flex;
		justify-content: space-between;
		border-top: 1px solid rgba(255,255,255,0.5);
		padding-top: 3rem;
		margin-top: 4rem;
		font-weight: var(--fw-bold);
	}
	@element delete {
		flex-shrink: 0;
		margin-right: 2.4rem;
		transition: color 0.15s ease-in-out;
		grid-area: delete;

		& > * {
			pointer-events:none;
		}

		&:hover, &:active, &:focus{
			color: hsl(var(--pink));
		}
	}
	@element form {
		color: hsl(var(--black-purple));

		& .form__element {
			margin: 0;
		}
	}

	@element fieldset-wrapper{
		background-color: hsl(var(--white));
		padding: 6rem var(--m-inside) 6rem 6rem;
		margin: 0;
		position: relative;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
		border-radius: 0 0 10px 10px;

		@media (--max48){
			padding: 4rem var(--m-inside);
		}
	}

	@element fieldset{
		/* position: absolute; */
		grid-area: 1 / 1;
		opacity: 0;
		transform: translateX(35px);
		pointer-events: none;
		min-width: 0;
	}

	@element form-tabs{
		display: flex;
	}
	@element form-tab{
		background: hsla(var(--white), 0.05);
		padding: 2rem 4rem;
		text-align: center;
		color: hsl(var(--white));
		z-index: 1;
		border-radius: 10px 10px 0 0;
		transition: all 0.2s ease-out;

		&.adjacent{
			background: hsla(var(--white), 0.1);
		}

		&.active{
			background: hsl(var(--white));
			color: hsla(var(--pink));

			& > [class*="form-tab-number"]{
				color: hsl(var(--white));

				&:before{ background: hsla(var(--pink)); }
			}
		}

		@media (--max48){
			padding: 1rem 3rem;
		}
	}

	@element form-tab-number{
		padding: 1rem;
		color: hsl(var(--black-purple));
		text-align: center;
		position: relative;
		font-weight: var(--fw-bold);
		margin-bottom: -.5rem;
		display: inline-block;
		width: 3rem;

		&:before{
			content: '';
			width: 100%;
			position: absolute;
			aspect-ratio: 1/1;
			left: 0;
			background: hsla(var(--white)); 
			border-radius: 50%;
			z-index: -1;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	@element form-tab-name{
		display: block;
		font-weight: var(--fw-medium);

		@media (--max48){
			display: none;
		}
	}
	
}

html[data-theme="lightshow"] {
	& .c-offerteblob__item {
		color: hsl(var(--b-body));
	}
}