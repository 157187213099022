@block g-cta__v1 {
	@element grid {
		display: grid;
		grid-template-areas: "text";
		grid-template-columns: 66%;
		grid-template-columns: min(110rem, 100%);
		grid-gap: 12rem;

		/* autoprefixer: ignore next */
		justify-content: center;

		& > [class*="__col--text"] {
			grid-area: text;
			align-self: center;
			justify-self: center; 
		}
	}
	@element col {
		text-align: center;
		padding-bottom: var(--m-base);

		& > :--heading {
			color: hsl(var(--alt));
		}
		& > p {
			line-height: 1.9;
			font-weight: 400;
		}
		& > .btn {
			margin-top: 3rem;
		}
	}
}
