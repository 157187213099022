@block c-hero-banner{
    overflow: hidden;
    background: hsl(var(--secondary));
    padding-top: 6rem;
    padding-bottom: 6rem;
    display: grid;
    grid-template-columns: 100%;
    grid-auto-rows: auto;
    gap: var(--m-base);

    @media (--min64) {
        grid-template-columns: 1fr 1fr;
    }

    &:before{
        content: "";
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMTYxLjU2MiIgaGVpZ2h0PSIxMTYxLjQ4NCIgeG1sbnM6dj0iaHR0cHM6Ly92ZWN0YS5pby9uYW5vIj48cGF0aCBkPSJNMTQ2LjY0NSA2MzEuMjY4YzEzLjkzNyA0MC40NzYgMzAuMjk2IDgwLjY3NyA0Ny4yNTQgMTIxLjc3NiA0Ny41NTYgMTE1Ljc5NiAxMDEuNDU3IDI0Ny4wNDMgMTg3LjY2MSAyODUuNzY3IDcyLjQ1NiAzMi42ODQgMTg0LjgxMiAxMS4yMDEgMzQzLjQ4Ni02NS42NzUgMTgwLjkxOS04Ny42NTQgMjgwLjcwMS0xNzguMTI2IDMwNS4wNzktMjc2LjY2MyAxMS41NjYtNDYuMjcxIDcuMjUyLTEwMS4wMjQtMTIuNDgzLTE1OC4zMzctMjYuOTU3LTc4LjI4OS04MS40NzgtMTU3LjY5MS0xNDkuNTMzLTIxNy44MTEtMTEzLjY0OC05OS45ODQtMjY1LjQ5Mi0xNDguMDg3LTQxNi42MjgtMTMxLjk3Ni0xNTIuNDIxIDE2LjA3MS0yODYuMTIxIDk1LjMzOC0zMjUuMTY1IDE5Mi44NDMtMjQuNTUzIDYwLjgxNi0xOC4yODIgMTM3Ljk0MiAyMC4zMjkgMjUwLjA3N20tMjguMzY2IDkuNzY3Qzg1LjA5MyA1NDQuNjUyIDY2LjQ1MSA0NDkuMzQgOTguNDk1IDM2OS45NjJjNDUuNDc3LTExMy41NjcgMTk0LjI3MS0xOTUuMDQ1IDM0OS44MzgtMjExLjQ0NyAxNTUuMjYxLTE2LjU1IDMxNy41MjEgMzEuODkyIDQzOS41OTYgMTM5LjI4MyAxMjEuOTIxIDEwNy43MDEgMjA0LjE4OCAyNzQuNDIxIDE3MS4zMDQgNDA1Ljk0NS0zMi41NzUgMTMxLjY3OS0xODAuMzAyIDIyOC4xNjctMzIxLjEgMjk2LjM4N3MtMjc1LjE0NCAxMDguMzI1LTM2OC45MDcgNjYuMDI1Yy05My45OTEtNDIuMjIxLTE0Ny41NTctMTY2LjUzMy0yMDMuMDc5LTMwMS43MTUtMTYuNzI5LTQwLjU0NC0zMy42MzctODIuMDc3LTQ3Ljg2OC0xMjMuNDA2eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
        background-position: 80% 20%;
        background-repeat: no-repeat;
        background-size: 150%;
        position: absolute;
        aspect-ratio: 1/1;
        height: 100%;
        width: auto;
        max-width: 100%;
        fill: #fff;
        left: 0;
        bottom: 0;
        opacity: .15;
        z-index: 0;
    }

    @modifier with-slides{
        grid-template-columns: 100%;

        

        @media (--min64) {
            grid-template-columns: 1fr 42.5%;
        }
    }

    @modifier sm{
        @media (--min64) {
            grid-template-columns: 1.75fr 1fr;
        }
    }

	@element content-holder {
        display: flex;
        flex-direction: column;
        gap: var(--m-base);
        grid-area: 1 / 1;
        
        & > .btn-holder{
            @media (--min80) {
                display: none;
            }
        }

        @media (--max64) {
            padding-top: 15vh;
        }
	}
    
	@element heading {
        color: hsl(var(--primary));
        font-size: var(--fz-title);

        @modifier sm{
            font-size: var(--fz-title--sm);
            line-height: 0.9;
            word-wrap: break-word;
        }
    }
    
    @element subheading {
        color: hsl(var(--themed-text));
        font-weight: var(--fw-bold);
    }

    @element content {
        z-index: var(--z-pageheader);

        & > .rte{
            color: hsl(var(--themed-text));
        }
    }

	@element blob-image {
        grid-area: 1 / 2;
        position: relative;
    
        @media (--max80) {
            width: 50rem;
            height: 40rem;
        }
        
        @media (--max64) {
            grid-area: 1 / 1;
            margin-left: auto;
            max-width: 60%;
            height: auto;
            aspect-ratio: 4/3;
        }
        
        & > .l-image__parent{
            width: calc(100% + var(--m-inside));
            height: calc(100% + 6rem);
            top: -6rem;
            right: 0;
            clip-path: url(#sfeerconcepten-blob);
        }

        & > .l-image__parent--fh{
            @media (--min80) {
                height: calc(100% + 12rem);
                clip-path: url(#sfeerconcepten-blob-alt);
            }
        }

        & > .l-image__parent--square{
            aspect-ratio: 16 / 10;
        }
	}
	
    @element blob-tag{
        position: absolute;
        width: 20rem;
        height: 20rem;
        color: #fff;
        bottom: 0;
        right: 60%;

        @media (--max64) {
            width: 13rem;
            height: 13rem;
            top: 0;
            right: 100%;
        }
    }

    @element blob-tag-bg{
        width: 100%;
        height: 100%;
        transform: rotate(30deg);
    }

    @element blob-tag-icon{
        color: hsl(var(--black-purple));
        position: absolute;
        width: 50%;
        height: 50%;
        left: 50%;
        top: 52%;
        transform: translate(-50%, -50%);

        & > img{
            object-fit: contain;
        }
    }

    @element backlink {
		color: hsl(var(--themed-text--alt));
		font-weight: 500;
		transition: color .2s ease-out;
        background-color: hsl(var(--secondary--alt));
        padding-top: 2.5rem;
        padding-bottom: 2rem;

        & > a {color: hsl(var(--themed-text--alt)); text-decoration: none;}

		&:hover {
			color: hsl(var(--primary));
		}
		&:focus {
			outline: 0;
		}

		@media (--min64) {
			display: none;
		}
	}

    @element btn-holder{
        display: flex;
        gap: 2rem;
        align-items: center;
        align-self: center;
        justify-content: end;

        @media (--max64) {
            flex-flow: column wrap;
        }
    }

    @element slider {
        grid-area: 1 / 2;
        width: 100%;
        align-self: end;

        @media (--max64) {
            grid-area: 2 / 1;
        }
    }

    @element single-image {
        display: flex;
        align-items: center;
    }
}

.js-heading{
    opacity: 0;
    transform: translateX(-20px);
}
.js-subheading{
    opacity: 0;
    transform: translateY(-10px);
}
.js-header-image, .js-header-slider, .js-header-breadcrumbs{
    opacity: 0;
}

