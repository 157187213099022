@block c-scrollwindow {
	@element holder {
		grid-template-areas: "list blob blob";
		grid-template-rows: minmax(0, 1fr);
	}
	@element container {
		grid-area: 1/2/2/4;
		width: 100%;
		height: 100%;
		display: none;
		background: #fff;
		overflow: hidden;
	}
	@element grid {
		/* autoprefixer grid: off */
		display: grid;
		grid-template-areas: "items blobholder";
		grid-template-columns: 48rem minmax(0, 1fr);
		grid-column-gap: 6rem;
		width: 100%;
		z-index: 100;
		overflow-x: hidden;
		overflow-y: scroll;
		& > [class*="__items"] {
			grid-area: items;
		}
		& > [class*="__blob-holder"] {
			grid-area: blobholder;
		}
		@media (--max48) {
			grid-template-areas: "blobholder" "items";
			grid-template-columns: minmax(0, 1fr);
			grid-template-rows: minmax(0, 1fr) 100px;
			grid-column-gap: 0rem;
			grid-row-gap: 0rem;
		}
	}
	@element item-holder {
		height: 100%;
		@media (--max48) {
			z-index: 1;
		}
	}
	@element item-inner {
		padding: 42vh 0;
		@media (--max48) {
			height: 100%;
			padding: 0 0 1rem 0;
			display: flex;
			align-items: center;

		}
	}
	@element scroll-indicator {
		position: absolute;
		z-index: 1;
		bottom: 6rem;
		left: 50rem;
		text-transform: uppercase;
		font-weight: 600;
		opacity: 0;
		display: none;
		& svg {
			transform: rotate(90deg);
		}
		@media (--max48) {
			display: none;
		}
	}
	@element swipe-indicator {
		position: absolute;
		bottom: 5px;
		text-align: center;
		pointer-events: none;
		text-transform: uppercase;
		font-weight: 600;
		width: 100%;
		font-size: 12px;
		color: hsl(var(--secondary));
		display: flex;
		justify-content: center;
		@media (--min48) {
			display: none;
		}
	}
	@element item {
	    transition: transform .2s ease-in-out;
	    cursor: pointer;
	    text-transform: uppercase;
	    @media (--min48) {
	    	max-width: calc(100% - 8rem);
	    }
	    @media (--max48) {
	    	white-space: nowrap;
	    	padding: 0 40px;
	    	&:first-of-type {
	    		padding-left: 30vw;
	    	}
	    	&:last-of-type {
	    		padding-right: 30vw;
	    	}
	    }
	    &.s-active {
	    	opacity: 1;
	    	transform: translateX(10px);
	    	& [class*="__arrow"] {
		    	opacity: 1;
		    	transform: translateY(0px);
		    	transition: opacity .15s .1s ease, transform .4s ease-out;
		    }
		}
		&:nth-of-type(4n + 1) {
			color: hsl(var(--primary));
		}
		&:nth-of-type(4n + 2) {
			color: hsl(var(--secondary));
		}
		&:nth-of-type(4n + 3) {
			color: hsl(var(--alt));
		}
		&:nth-of-type(4n + 4) {
			color: hsl(var(--extra));
		}
		&:hover {
			opacity: .8;
		}
	}
	@element blob-holder {
		height: 100%;
		position: relative 0 0 0 0;
		pointer-events: none;
	}
	@element blob-inner {
		height: 100%;
		background: hsl(var(--c-body));
		color: #fff;
		border-radius: 90% / 110% 110% 100% 100%;
		overflow: hidden;
		padding: 20vh 12vw;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: fixed;
		@media (--min48) {
			right: 0;
			width: 50vw;
		}
		&::after {
			content:"";
			position: absolute 0 0 0 0;
			z-index: 1;
			background: linear-gradient(4deg, rgba(0,0,0,0.8) 10%, rgba(0,0,0,0) 90%);
		}

		@supports (clip-path: url(#a)) {
			border-radius: 0;
			overflow: visible;
			clip-path: url(#sfeerconcepten-blob);
		}
		@media (--max48) {
			padding: 0;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			@supports (clip-path: url(#a)) {
				border-radius: 0;
				overflow: visible;
				clip-path: url(#sfeerconcepten-blob-mob);
			}
		}
	}
	@element blob-item {
		min-width: 100vw;
		height: 130vh;
		margin-top: -13vh;
		position: relative;
	}
	@element article {
		max-width: 62rem;
		position: relative;
		z-index: 2;
		opacity: 0;
		& a, & .btn {
			pointer-events: auto;
		}
		@media (--max48) {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			height: calc(100% - 6rem);
			max-width: none;
			overflow: hidden;
			& > header {
				flex: 0 0 auto;
			}
			& > [class*="__content"]  {
				overflow: hidden;
			    display: flex;
			    flex-direction: column;
			    align-items: flex-start;

				& > [class*="__text"] {
					flex: 0 1 auto;
					max-height: none;
				}
				& > [class*="btn"] {
					flex: 0 0 auto;
				}
			}
		}
	}
	@element tab {
		display: none;
		&.s-active {
			display: block;
			@media (--max48) {
				padding: 8rem 2rem;
				display: flex;
				align-items: flex-end;
				height: 100%;
			}
		}
	}
	@element main-title {
		text-shadow: 1px 2px 20px rgba(0,0,0,0.1);
		line-height: 0.9;
	}
	@element sub {
		display: inline-block;
		font-size: var(--fz-30);
		font-weight: var(--fw-bold);
		margin-top: 1rem;
	}
	@element text {
		max-height: 24vh;
		overflow: hidden;
		& > p:first-child {
			margin-top: 0
		}
		& > p:last-child {
			margin-bottom: 0
		}
	}
	@element picture {
		position: absolute 0 0 0 0;
		z-index: 0;
	}
	@element text-holder {
		position: relative;
		overflow-wrap: break-word;
		line-height: var(--lh-sfeerconcept);
	}
	@element arrow {
		position: absolute;
		left: 105%;
		bottom: 1.5rem;
		opacity: 0;
		pointer-events: none;
		transform: translateX(-30px);
		transition: opacity .3s ease, transform .0s .4s linear;

	}
}

html[data-theme="tuinfeest"] {
	& .c-scrollwindow__main-title {
		margin-bottom: 3rem;
	}
}

html[data-theme="lightshow"] {
	& .c-scrollwindow__container {
		background: var(--bg-overlay);
		color: var(--fg-overlay);
	}
	& .c-scrollwindow__blob-inner {
		background: hsl(var(--b-body));
	}
	& .c-scrollwindow__item {
		&:nth-of-type(4n + 4), &:nth-of-type(4n + 2) {
			& .c-linkelem__icon {
				color: hsl(var(--b-body));
			}
		}
	}
}
