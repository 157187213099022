@block c-end-pagination{
    /* autoprefixer: off */
    display: flex;
    gap: var(--m-small);
    flex-flow: row wrap;
    margin-bottom: -8rem;

    @media (--max80) {
        padding-top: 3rem;
    }

    @element border{
        border-top: 1px solid hsla(var(--black-purple), 0.15);
    }

    @element backlink{
        margin-right: auto;
        text-decoration: none;
        font-size: 2.4rem;
        transition: color 0.15s ease-out;
        font-weight: var(--fw-bold);
        color: hsl(var(--body));

        & > span a{
            text-decoration: none;
        }

        &:hover, &:active {
            color: hsl(var(--primary));
        }
        
        @media (--max80) {
            width: 100%;
        }
    }

    @element item{
        /* autoprefixer: off */
        display: grid;
        grid-template-areas: "image label"
                             "image link";
        grid-template-columns: auto 1fr;
        grid-template-rows: 1fr 1fr;
        column-gap: 1.5rem;

        @modifier reverse{
            grid-template-areas: "label image"
                                 "link image";
            
            & > [class*="label"]{
                text-align: right;
            }

            @media (--max80) {
                margin-left: auto;
            }
        }
    }
    
    @element image{
        /* autoprefixer: off */
        grid-area: image;
        width: 12rem;

        @media (--min48) {
            width: 16rem;
        }
    }

    @element label{
        /* autoprefixer: off */
        grid-area: label;
        align-self: end;
        font-size: 1.6rem;
        opacity: 0.5;
    }

    @element link{
        /* autoprefixer: off */
        grid-area: link;
        color: hsl(var(--black-purple));
        text-decoration: none;
        display: flex;
        align-items: center;
        align-self: start;
        column-gap: .5rem;
        flex-shrink: 0;

        & > * {
            transition: color 0.15s ease-out;
        }

        & > h4{
            color: inherit;
        }

        &[rel="prev"]{
            &:before{
                content: "\25C2";
            }
        }
        

        &[rel="next"]{
            &:after{
                content: "\25B8";
            }
        }

        &:hover, &:active{
            color: hsl(var(--primary));
        }
    }
}