@block c-anchor-menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid hsla(var(--black-purple), 0.2);
    margin-bottom: 10rem;
    min-height: 8rem;
    overflow-x: auto;
    scroll-snap-type: x proximity;
    z-index: var(--z-pageheader);
    transform: translate(0px);
    transition: all 0.2s ease-in;
    
    @media (--min64) {
        min-height: 10rem;
    }

    @element link-holder{
        display: flex;
        column-gap: var(--m-small);

        @media (--max64) {
            column-gap: var(--m-grid);
        }
    }

    @element link{
        font-size: 1.4rem;
        text-transform: uppercase;
        text-decoration: none;
        color: hsl(var(--black-purple));
        padding: 2rem 0;
        border-bottom: 2px solid transparent;
        transition: all 0.15s ease-out;
        white-space: nowrap;
        scroll-snap-align: center;
        font-weight: var(--fw-bold);
        letter-spacing: .1rem;

        &:hover {
            border-bottom: 2px solid hsl(var(--primary));
			color: hsl(var(--primary));
		}
		&:focus, 
		&:focus-visible {
			outline: 2px dashed hsl(var(--primary));
			outline-offset: 2px;
		}

         @media (--min64) {
            padding: 4rem 0;
        }
    }

    @media (--max80) {
        & > .btn-holder{ display: none; }
    }
}
.c-anchor-menu__parent{
    top: 7rem;
    z-index: var(--z-modal);
    position: sticky;
    background: hsl(var(--white));

    @media (--min64) {
        top: 10rem;
    }
}
