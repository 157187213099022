@block c-tile{
    position: relative;
    grid-area: span 1 / span 1;
/* 
    @media (--min100){
        grid-area: span 1 / span 1;
    } */

    @element category{
        color: hsl(var(--white));
        background: hsl(var(--black-purple));
        position: absolute;
        padding: .5rem 1rem;
        font-size: 1.4rem;
        border-radius: 25px;
        top: 1.75rem;
        right: 1.75rem;
        margin: 0;
    }

    @element link{
        color: hsl(var(--black-purple));
        text-decoration: none;
        display: flex;
        align-items: center;
        
        & > [class*="l-icon"]{
            display: inline-block;
            transition: all 0.3s ease-out;
            opacity: 0;
            transform: translateX(-3rem);
            visibility: hidden;
            margin-right: 1rem;
            flex-shrink: 0;
        }

        &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
        }

        &:hover{
            color: hsl(var(--primary));

            & > [class*="l-icon"]{
                visibility: visible;
                opacity: 1;
                transform: translateX(0);
            }

            & > [class*="title"]{
                transform: translateX(0);
            }
        }
    }

    @element title{
        color: inherit;
        transition: all 0.2s ease-out;
        transform: translateX(-3rem);
    }


    @element text{
        font-size: 1.7rem;
        line-height: 1.5;

        & > p{
            margin: 0;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
        }
    }

    @element image{
        margin-bottom: 1rem;
        background-color: hsla(var(--black-purple), 0.05);
    }

    @element logo{
        width: 8rem;
        position: absolute;
        bottom: 0;
        right: 1.75rem;
        margin: 0;
        transform: translateY(-100%);
    }

}