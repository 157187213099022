@block c-pagination{

    @element wrapper{
        display: flex;
        gap: var(--m-small);
        justify-content: center;
    }

    @element link{
        font-size: var(--fz-24);
        color: hsl(var(--black-purple));
        font-weight: var(--fw-bold);
        transition: color 0.15s ease-in-out;
        text-decoration: none;
        
        &:hover, &:active, &:focus{
            color: hsl(var(--primary));
        }
    }

}