@block c-homepage {

	@element header {
		overflow: hidden;
		background: linear-gradient(15deg, hsl(var(--orange-red)) 0%, hsl(var(--yellow)) 50%);
 
		&:before{
			content: "";
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMTYxLjU2MiIgaGVpZ2h0PSIxMTYxLjQ4NCIgeG1sbnM6dj0iaHR0cHM6Ly92ZWN0YS5pby9uYW5vIj48cGF0aCBkPSJNMTQ2LjY0NSA2MzEuMjY4YzEzLjkzNyA0MC40NzYgMzAuMjk2IDgwLjY3NyA0Ny4yNTQgMTIxLjc3NiA0Ny41NTYgMTE1Ljc5NiAxMDEuNDU3IDI0Ny4wNDMgMTg3LjY2MSAyODUuNzY3IDcyLjQ1NiAzMi42ODQgMTg0LjgxMiAxMS4yMDEgMzQzLjQ4Ni02NS42NzUgMTgwLjkxOS04Ny42NTQgMjgwLjcwMS0xNzguMTI2IDMwNS4wNzktMjc2LjY2MyAxMS41NjYtNDYuMjcxIDcuMjUyLTEwMS4wMjQtMTIuNDgzLTE1OC4zMzctMjYuOTU3LTc4LjI4OS04MS40NzgtMTU3LjY5MS0xNDkuNTMzLTIxNy44MTEtMTEzLjY0OC05OS45ODQtMjY1LjQ5Mi0xNDguMDg3LTQxNi42MjgtMTMxLjk3Ni0xNTIuNDIxIDE2LjA3MS0yODYuMTIxIDk1LjMzOC0zMjUuMTY1IDE5Mi44NDMtMjQuNTUzIDYwLjgxNi0xOC4yODIgMTM3Ljk0MiAyMC4zMjkgMjUwLjA3N20tMjguMzY2IDkuNzY3Qzg1LjA5MyA1NDQuNjUyIDY2LjQ1MSA0NDkuMzQgOTguNDk1IDM2OS45NjJjNDUuNDc3LTExMy41NjcgMTk0LjI3MS0xOTUuMDQ1IDM0OS44MzgtMjExLjQ0NyAxNTUuMjYxLTE2LjU1IDMxNy41MjEgMzEuODkyIDQzOS41OTYgMTM5LjI4MyAxMjEuOTIxIDEwNy43MDEgMjA0LjE4OCAyNzQuNDIxIDE3MS4zMDQgNDA1Ljk0NS0zMi41NzUgMTMxLjY3OS0xODAuMzAyIDIyOC4xNjctMzIxLjEgMjk2LjM4N3MtMjc1LjE0NCAxMDguMzI1LTM2OC45MDcgNjYuMDI1Yy05My45OTEtNDIuMjIxLTE0Ny41NTctMTY2LjUzMy0yMDMuMDc5LTMwMS43MTUtMTYuNzI5LTQwLjU0NC0zMy42MzctODIuMDc3LTQ3Ljg2OC0xMjMuNDA2eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
			background-position: top right;
			background-repeat: no-repeat;
			background-size: 140%;
			position: absolute;
			aspect-ratio: 1/1;
			height: 110%;
			width: auto;
			max-width: 100%;
			fill: #fff;
			left: 0;
			bottom: 0;
			opacity: .15;
			height: 70%;
			z-index: 0;
		}

		@media (--min48) {
			&:before{
				height: 110%;
			}
		}
	}

	@element header-inner {
		padding: 12rem 0 14rem;
	}

	@element heading {
		color: hsl(var(--white));
		font-size: var(--fz-homepage-title);
		z-index: var(--z-pageheader);
		opacity: 0;
	}

	@element subheading {
		color: hsl(var(--black-purple));
		font-weight: var(--fw-bold);
		z-index: var(--z-pageheader);
	}

	@element links {
		max-width: 450px;
	}
	@element links__text {
		font-size: var(--fz-body);
		font-weight: 400;
		max-width: 450px;
		z-index: var(--z-pageheader);
		position: relative;
		opacity: 0;
	}
	@element blob {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		height: 70vw;
		opacity: 0;

		@media (--min36) {
			height: 50vw;
			width: 50vw;	
		}

		@media (--min80) {
			/* margin-right: -20rem; */
			/* margin-right: -12rem; */
			width: 60%;
			height: auto;
		}
		@media (--ie) {
			position: relative;
		}
	}

	@element slick-holder {
		width: 100%;
		height: 100%;
		position: relative;
		
		@media (--min64) {
			max-width: 100%;
		}
	}

	@element blob-slick {
		width: 100%;
		height: 100%;
		position: relative;

		& > .slick-list,
		& .slick-track,
		& .slick-slide,
		& .slick-slide > div {
			height: 100%;
		}

		& .slick-slide {
			& .btn {
				background-color: hsl(var(--primary));
			}
			&:nth-of-type(4n + 2) .btn {
				background-color: hsl(var(--secondary));
			}
			&:nth-of-type(4n + 3) .btn {
				background-color: hsl(var(--alt));
			}
			&:nth-of-type(4n + 4) .btn {
				background-color: hsl(var(--extra));
			}
		}

		& > .slick-dots {
			position: absolute auto 50% 4rem auto;
			margin: 0;
			display: flex !important;
			justify-content: flex-end;
			list-style: none;

			& > li {
				& + li {
					margin-left: 1rem;
				}
				& > button {
					border-radius: 50%;
					appearance: none;
					outline: 0;
					background: transparent;
					border: 2px solid #fff;
					font-size: 0px;
					width: 1.4rem!important;
					height: 1.4rem!important;
					transition: opacity .4s ease;
				}
				&.slick-active > button {
					background: #fff;
				}
			}

			@media (--max48) {
				display: none !important;
			}
			
			@media (--max80) {
				right: 28%;
			}
		}
	}

	@element blob-slide {
		height: 100%;
		position: relative;
		& picture {
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			margin-top: 0;
		}
		& .l-image__item.l-image__item--loading {
			opacity: 1 !important;
		}
	}
	
	@element blob-inner {
		height: 100%;
		color: hsl(var(--b-body));
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		position: relative;
		z-index: 1;
		clip-path: url(#homepage-blob-mobile);

		&:after {
			content: "";
		    position: absolute;
		    top: 0;
		    right: 0;
		    bottom: 0;
		    left: 0;
		    z-index: -1;
		    background: rgba(0,0,0,0);
		}
		@media (--min64) {
		}
		@media (--min80) {
			clip-path: url(#homepage-blob);
			/* min-height: 80rem; */
			/* clip-path: url(#homepage-blob-large); */
		}
		@media (--min120) {
			/* min-height: 100rem; */
		}
	}
	
	@element sfeerblob-container {
		position: absolute auto -3rem 14rem auto;
		font-size: var(--fz-60);
		font-size: 6rem;
		text-align: right;
		color: #fff;
		width: 6em;
		height: 6em;
		display: flex;
		align-items: center;
		justify-content: center;
		background: transparent;
		border: 0;
    	outline-offset: 0px;
    	overflow: hidden;
		&:hover {
			& [class*="__sfeerblob-blob"] {
				fill: hsl(var(--primary-h), var(--primary-s), calc(var(--primary-l) - 10%));
			}
		}
		&:focus {
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed hsl(var(--primary-h), var(--primary-s), calc(var(--primary-l) - 10%));
		}

		@media (--max48) {
			right: 6rem;
		}

	}
	/* @element sfeerblob-text {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 10;
	}
	@element sfeerblob-title {
		padding: 0;
		font-size: inherit;
		z-index: 10;
		width: 70%;
		padding-right: 2.2rem;
		line-height: 1;
	}
	@element sfeerblob-icon {
		position: relative 1.7rem auto auto -4rem;
	}
	@element sfeerblob-blob {
		fill: hsl(var(--primary));
		transform: rotate(-165deg);
		position: absolute 0 0 0 0;
		width: 100%;
		height: 100%;
		margin: auto;
		transition: fill 0.15s ease;
	} */
}
