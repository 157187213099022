@block cb-events{

    & > .btn{
        margin-top: 4rem;
    }

    @element header{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        gap: var(--m-base);
        margin-bottom: var(--m-grid);

        @media (--min48) {
            grid-template-columns: 1fr 1fr; 
        }
    }

    @element title{
        grid-area: 1 / 1;
        align-items: center;

        & > h2 { line-height: 1; }
        & > h4 { color: var(--black-purple); line-height: 1.1; }
    }

    @element description{
        grid-area: 2 / 1;

        & > p { margin: 0; }

        @media (--min48) {
            grid-area: 1 / 2;
        }
    }

    @element wrapper{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: auto;
        gap: 1.5rem;

        @media (--min36) {
            grid-template-columns: repeat(2, 1fr);
            gap: var(--m-base);
        }
        @media (--min48) {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    
    @element item{
        padding: var(--m-base);
        color: hsl(var(--white));
        background: hsl(var(--black-purple));
        display: flex;
        align-items: center;
        justify-content: center;
        aspect-ratio: 16/10;
        position: relative;
    }

     @element image{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        aspect-ratio: 16/10;
    }

    @element link{
        z-index: 20;
        color: hsl(var(--white));
        text-decoration: none;
        font-weight: var(--fw-bold);
        text-align: center;
        font-size: 2.4rem;

        &:before{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: background-color 0.2s ease-in;
            background-color: hsla(var(--black-purple), 0.5);
            z-index: -1;
        }

        &:hover, &:focus{
            &:before{
                background-color: hsla(var(--black-purple), 0.2);
            }
        }
    }
}