@block g-tekst-groot__v1 {
	@element grid {
		display: grid;
		grid-template-areas: "text";
		grid-template-columns: 66%;
		grid-template-columns: min(110rem, 100%);
		grid-gap: 12rem;

		/* autoprefixer: ignore next */
		justify-content: start;

		& > [class*="__col--text"] {
			grid-area: text;
			align-self: center;
			justify-self: center;
			width: 100%;
		}
	}
	@element col {
		& > :--heading {
			color: hsl(var(--primary));
		}
		& > p {
			line-height: 1.5;
			font-weight: 400;
		}
		& > .btn {
			margin-top: 3rem;
		}
	}
}