html {
	font-size: 56.25%;

	@media (--max24) {
		font-size: 50%;
	}
	
	@media (--min48) {
		font-size: 58%;
	}

	@media (--min124) {
		font-size: 60%;
	}

	/* @media (--min120) {
		font-size: 62.5%;
	} */
}

@custom-selector :--heading h1, .h1, h2, .h2, h3, h4, h5, h6;
@custom-selector :--enter :hover, :focus;

/* BASE FONT-SIZE FOR PROJECT */

body {
	font-size: var(--fz-body);
	line-height: 1.75;
	font-family: var(--font-base);
	font-weight: var(--fw-body);
	color: hsl(var(--c-body));
	background: hsl(var(--b-body));
}

/* BASIC TEXT STYLING */

strong,
.bold {
	font-weight: 700;
}
em,
i,
.italic {
	font-style: italic;
}
a:not([class]) {
	color: hsl(var(--primary));
	color: currentColor;
	text-decoration: underline;
	/* @mixin transition; */

	&:hover {
		opacity: 0.8;
	}
}
ul:not([class]),
ol:not([class]) {
	padding-left: 1.6rem;
}
li:not([class])::marker{	
	color: hsl(var(--primary));
}
a,
button {
	cursor: pointer;
}
a[href^="tel:"] {
	text-decoration: none;
}

/* HEADINGS */
:--heading {
	font-weight: var(--fw-title);
	margin: 0;
	line-height: 1.1;
	color: hsl(var(--primary));
	position: relative;
}
.subtitle {
	font-size: var(--fz-24);
	display: inline-block;
}

h1,
.h1 {
	font-family: var(--font-title);
	font-size: var(--fz-title);
	line-height: var(--lh-title);
	font-weight: var(--fw-bold);
}
h2,
.h2 {
	font-size: var(--fz-h2);
}
h3,
.h3 {
	font-size: var(--fz-30);
}
h4,
.h4 {
	font-size: var(--fz-24);
}

.pink{
	color: hsl(var(--pink));
}

/* RESET ITEMS */

main:focus {
	outline: 0;
}
p:empty {
	display: none;
	margin: 0;
}



.themed-content{
	color: hsl(var(--themed-text));
}


@block rte {
	& ul > li + li {
		margin-top: 0.7em;
	}
	@element list {
		@modifier icon-check {
			padding: 0;
			& li {
				display: flex;
				& + li {
					margin-top: var(--m-small);
				}
				&::before {
					content: "";
					width: 2.5rem;
					height: 2.5rem;
					min-width: 2.5rem;
					max-width: 2.5rem;
					min-height: 2.5rem;
					max-height: 2.5rem;
					margin-top: 0.4rem;
					margin-right: 2rem;
					display: block;
					background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNSAyNSI+PHBhdGggZmlsbD0iIzExYmM5YiIgZD0iTTEyLjUgMEExMi41IDEyLjUgMCAxIDAgMjUgMTIuNSAxMi41NDkgMTIuNTQ5IDAgMCAwIDEyLjUgMHptLTEuNTEzIDE4LjE4NmwtNS40NzQtNS40NzQgMi4wNzItMi4wNzIgMy41IDMuNSA3LjAyMy02LjM4NCAxLjk3MSAyLjE2N3oiLz48L3N2Zz4=);
				}
			}
		}

		@modifier column-2 {
			column-count: 2;
			column-gap: var(--m-base);
		}
		@modifier md-column-2 {
			@media (--min48) {
				column-count: 2;
				column-gap: var(--m-base);
			}
		}
	}
	@modifier large {
		font-size: var(--fz-24);
	}

	@modifier read-more-holder {
		position: relative;
		&.s-overflow {
			height: 200px;
			overflow: hidden;
			& > .rte--read-more {
				display: flex;
			}
		}
	}
	@modifier read-more {
		display: none;
		justify-content: center;
		font-size: 14px;
		padding: 10px;
		color: #737373;
		position: relative;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		cursor: pointer;
		transition: opacity 0.1s ease-in-out;

		&:--enter {
			opacity: 0.9;
		}

		& > span {
			z-index: 10;
			position: relative;
		}

		&::before {
			content: "";
			display: block;
			background: linear-gradient(0deg, #e4e4e4, #ffffffc9);
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			top: auto;
			height: 100%;
		}
	}

	& table {
		width: 100%;
		max-width: 100%;
		height: auto !important;
		margin: var(--m-small) 0;
		border: none;
		font-size: var(--fz-14);
		border-collapse: collapse;
		overflow: hidden;
		overflow-x: auto;
		-webkit-overflow-scrolling: touch;

		&.no-margin {
			margin: 0;	
		}
	}

	& tbody {
		width: 100%;
	}

	& thead {
		width: 100%;
	}

	& tr {
		border: none;
	}

	& tr:nth-of-type(odd) {
		background: rgba(0,0,0,0.05);
	}

	& th {
		text-align: left;
		vertical-align: top;
		border: none;
		padding: 1rem var(--m-small);
		font-weight: var(--fw-heavy);
	}

	& td {
		text-align: left;
		vertical-align: top;
		border: none;
		padding: 1rem 2rem;
		width: 50%;
	} 
}

/*  RESET FONT-SIZE TO DISABLE ZOOM ON IPHONE WHEN INPUT IS SELECTED */

@media (--max48) {
	input[type="text"],
	input[type="search"],
	input[type="tel"],
	input[type="email"],
	input[type="date"],
	input[type="number"],
	input[type="password"],
	textarea,
	select {
		font-size: 16px;
	}
}

html[data-theme="lightshow"] {
	& .rte tr:nth-of-type(odd) {
		background: rgba(0,0,0,0.25);
	}
}
