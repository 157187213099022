input,
select {
	-webkit-appearance: none !important;
	appearance: none !important;
	font-family: 'Outfit';
}
input::-webkit-inner-spin-button {
	-webkit-appearance: none !important;
	appearance: none !important;
}
select::-ms-expand {
	display: none !important;
}
select {
	background-position: right var(--m-small) center;
	background-repeat: no-repeat;
	background-size: 10px 10px;
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxhYWdfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHZpZXdCb3g9IjAgMCA0MCA0MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDAgNDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj48Zz48ZyBpZD0iY2hldnJvbi1yaWdodF8xNF8iPjxwb2x5Z29uIHBvaW50cz0iMzksMTIuNyAzNC42LDguMyAyMCwyMi44IDUuNCw4LjMgMSwxMi43IDIwLDMxLjcgIi8+PC9nPjwvZz48L3N2Zz4=);
}

@block form {

	@element flex {
		display: flex;
	}

	@element element {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 80rem;
		margin: 0 auto;
		align-items: flex-start;
		& > * {
			width: 100%;
			& + * {
				margin-top: 1.5rem;
			}
		}
	}
	@element fieldset {
		border: 0;
		padding: 0;
	}
	@element legend {
		font-size: var(--fz-20);
		margin-bottom: 1rem;
	}
	@element item {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: 100%;
		& > * + * {
			margin-top: 1rem;
		}

		@modifier toggle {
			flex-flow: row wrap;
		}
	}
	@element row {
		display: flex;
		align-items: flex-end;
		column-gap: 2rem;
		
		&:not(:last-child){ margin-bottom: 2.5rem; }

		@media (--max48) {
			& > * + * {
				margin-top: 3.4rem;
			}
		}
		& > p {
			font-size: 1.4rem;
			line-height: 1.4;
		}
		& > p > strong,
		& > strong {
			font-size: 1.6rem;
			font-weight: var(--fw-bold);
		}

		@modifier gridrow {
			@supports (display: grid) {
				display: grid;
				/* autoprefixer: off */
				grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
				width: 100%;
				grid-gap: 2rem;
				column-gap: 2.4rem;

				@supports (
					grid-template-columns:
						repeat(auto-fit, minmax(min(12rem, 100%), 1fr))
				) {
					grid-template-columns: repeat(
						auto-fit,
						minmax(min(12rem, 100%), 1fr)
					);
				}
				& > *:not(:first-child) {
					margin: 0;
				}
				@media (--max48) {
					column-gap: 0.8rem;
				}
			}
		}
		@modifier buttonrow {
			justify-content: space-between;
			align-items: center;
			flex-direction: row;
		}
		@modifier order-reverse {
			& > *:first-child {
				order: 1;
			}
			& > *:last-child {
				margin-left: 0 !important;
				margin-top: 0 !important;
			}
			& > *:not(:last-child) {
				margin-left: 1rem;
			}
		}
		@media (--max48) {
			flex-direction: column;
		}
	}

	@element search {
		display: flex;
		flex-flow: row wrap;
	}

	@element label {
		width: 100%;
		font-weight: 600;
		font-family: var(--font-base);
		display: block;
		font-size: 2rem;
		line-height: 1.2;

		@modifier light {
			font-weight: var(--fw-regular);
			color: #fff;
		}
	}

	@element input {
		width: 100%;
		flex: 1;
		border: 1px solid #BCBCBC;
		font-size: var(--fz-16);
		padding: 1.6rem var(--m-small);
		font-family: var(--font-base);
		font-weight: var(--fw-base);
		border-radius: var(--radius);
		transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
		min-height: 5rem;

		@modifier no-border {
			border: 0;
		}

		@modifier half {
			width: calc(50% - 1rem);

			&:last-of-type(input) {
				margin-left: var(--m-small);
			}
		}

		@modifier small {
			padding: 1rem 2rem;
			font-size: var(--fz-16);
		}

		@modifier text {
			resize: vertical;
		}

		@modifier error {
			border-color: hsl(var(--error));
		}

		&.input-validation-error {
			border-color: hsl(var(--error));
		}

		&.valid {
			border-color: hsl(var(--green));
		}

		& + .btn {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}

		&:focus {
			border: 1px solid hsl(var(--pink));
			box-shadow: 0 0 0 1px hsl(var(--pink));
			outline: 0;
		}
		
		@media (--ie) {
			flex: auto;
		}
	}

	@element select{
		background-color: transparent;
	}

	@element checkbox__label {
		display: flex;
		align-items: center;
		position: relative;
		cursor: pointer;
		&::before {
			content: "";
			width: 2.4rem;
			height: 2.4rem;
			display: inline-block;
			background: rgba(255, 255, 255, 0.25);
			margin-right: 1.4rem;
			border-radius: var(--radius);
			border: 2px solid #000;
		}
		&::after {
			content: "";
			width: 2.4rem;
			height: 2.4rem;
			display: inline-block;
			background: #000;
			margin-right: 1.4rem;
			border-radius: var(--radius);
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: auto;
			margin: auto 0;
			opacity: 0;
			transform: scale(0);
			transition: transform 0.2s ease-in-out, background 0.2s ease-in-out;
		}
		&:hover::before {
			background: color-mod(var(--base) a(0.1));
		}

		@modifier rounded{
			&::before { 
				border-radius: 50%;
				transition: background 0.2s ease-in-out;
			}
			&::after { 
				border-radius: 0;
				height: 2.4rem;
				width: 1.8rem;
				border-bottom: 5px solid #fff;
				border-right: 5px solid #fff;
				background: none;
				left: 3px;
				top: -2px;
				transition: background 0.2s ease-in-out;
			}
		}
	}

	@element checkbox__input {
		&:checked + [class*="checkbox__label"]::after {
			opacity: 1;
			transform: scale(0.5) rotate(45deg);
		}
		&:checked + [class*="checkbox__label"]::before {
			background: #000;
		}
		&:focus + [class*="checkbox__label"]::before {
			border-width: 3px;
		}
	}

	@element radio__label {
		display: flex;
		align-items: center;
		position: relative;
		cursor: pointer;
		&::before {
			content: "";
			width: 2rem;
			height: 2rem;
			display: inline-block;
			background: hsla(var(--black-purple), 0.2);
			margin-right: 1.4rem;
			border-radius: 50%;
			border: 0px solid hsl(var(--black-purple));
			transition: transform 0.2s ease-in-out, background 0.2s ease-in-out;
		}
		&::after {
			content: "";
			width: 2rem;
			height: 2rem;
			display: inline-block;
			background: hsl(var(--black-purple));
			margin-right: 1.4rem;
			border-radius: 50%;
			position: absolute;
			margin: auto 0;
			opacity: 0;
			transform: scale(0);
			transition: transform 0.2s ease-in-out, background 0.2s ease-in-out;
		}
		&:hover::before {
			background: hsl(var(--black-purple));
		}
	}

	@element radio__input {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		&:checked + [class*="radio__label"]::after {
			opacity: 1;
			transform: scale(1);
		}
		&:checked + [class*="radio-list__item"] {
			background: hsl(var(--primary));
			border-color: hsl(var(--primary));
			color: #fff;
			border-radius: var(--radius);
			&::before {
				border-color: #fff;
				background: hsl(var(--primary));
			}
			&::after {
				background: #fff;
			}
		}

		&:checked + [class*="radio-list__item--alt"] {
			background: hsl(var(--pink));
			border-color: hsl(var(--pink));
			color: #fff;
			border-radius: var(--radius);
			&::before{
				border-color: #fff;
				background: hsl(var(--pink));
			}
		}

		&:focus + [class*="radio__label"]::before {
			border-width: 3px;
		}
	}

	@element cb-parent {
		padding: 0;
		border: 0;
	}

	@element cb-wrapper {
		display: flex;
		width: 100%;
	}

	@element cb-item {
		position: relative;
		overflow: hidden;
		flex: 1;
		text-align: center;
		border: 1px solid hsl(var(--light));
		min-width: 25%;
	}

	@element cb-item + @element cb-item {
		border-left: 0;
	}

	@element cb-input {
		position: absolute;
		right: 100%;
		bottom: 100%;
	}

	@element cb-label {
		padding: var(--m-small) var(--m-base);
		display: block;
	}

	@element cb-input:checked ~ @element cb-label {
		background: hsl(var(--primary));
		color: #fff;
	}

	@element error {
		color: hsl(var(--error));
	    background: transparant;
		border: 2px solid hsl(var(--error));
	    padding: 2px 10px;
	    font-size: var(--fz-14);
	    display: inline-block;
	    border-radius: var(--radius);
	    margin-top: 12px;
	}

	@element errorlist {
		color: hsl(var(--error));
		& ul {
			list-style: none;
			padding: 0;
		}
	}

	@element input-holder {
		display: flex;
		width: 100%;
		& > * {
			&:first-child:not(:last-child) {
				border-radius: var(--radius) 0 0 var(--radius);
			}
			&:last-child:not(:first-child) {
				border-radius: 0 var(--radius) var(--radius) 0;
				border-left: 0;
			}
			&:not(:first-child):not(:last-child) {
				border-radius: 0;
			}
		}
	}
	@element tile {
		display: flex;
		align-items: center;
		background: transparent;
		padding: 14px;
		border: 1px solid var(--border-gray);
	}
	@element radio-list {
		flex-direction: row;
		align-items: stretch;
	}

	@element radio-list__item {
		padding: 15px;
		margin: 0;
		border: 0;
		font-family: var(--font-base);
		font-weight: var(--fw-bold);
		font-size: var(--fz-18);
		transition: all 0.2s ease-in-out;
		@media (--min48) {
			padding: 20px;
		}
	}
}

@media (--max80) {
	.c-submit-form__form--header .btn {
		& > span {
			display: none;
		}
		& > svg {
			margin-left: 0 !important;
		}
	}
}
@media (--max48) {
	input {
		font-size: 16px !important;
	}
}

.umbraco-forms-field-wrapper {
	width: 100%;
	display: flex;
	flex-flow: column wrap;
}
.umbraco-forms-navigation {
	margin-top: 3rem;
}
.field-validation-error {
	color: #d80202;
}
.validation-summary-errors {
	background: #fbeded;
	padding: 20px;
    border-radius: var(--radius);
    border: 3px solid hsl(var(--error));
    margin-bottom: 3rem;
}
.c-overlay__holder--dark .form__error {
	color: hsl(var(--b-body));
    background: hsl(var(--secondary));
}