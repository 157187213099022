@block c-cookies {
	@element container {
		background: #fff;
		color: #000;
		position: fixed auto 1rem 1rem auto;
		z-index: 110;
		box-shadow: 15px 15px 35px rgba(0, 0, 0, 0.35);
		border-radius: calc(var(--radius) * 3);
		width: calc(100% - 2rem);
		font-size: var(--fz-12);
		@media (--min48) {
			right: 4rem;
			bottom: 4rem;
			max-width: calc(100% - 8rem);
			width: auto;
		}
		@media (--min64) {
			max-width: 32vw;
		}
		@media (--min100) {
			font-size: var(--fz-14);
		}
	}

	@element content {
		padding: var(--m-small);
		width: auto;
		max-width: none;
	}

	@element container--fullscreen {
		top: 0;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;

		& > [class*="__content"] {
			margin-bottom: 4rem;
		}
	}
	@element grid {
		display: flex;
		align-items: center;
		& > * + * {
			margin-left: 2rem;
		}
	}
	@element button {
		width: 100%;
		height: auto;
		margin: 0;
		padding: 1.2rem 2rem;
		text-align: center;
		& + [class*="btn"] {
			margin-top: 1rem;
		}
	}

	@element cookietext__holder {
		@media screen and (max-width: 600px) {
			max-height: 50vh;
			overflow: auto;
			-webkit-overflow-scrolling: touch;
		}
	}

	@element buttoncontainer {
		@media (--min64) {
			position: relative;
			display: flex;
			flex-direction: column;
			height: 100%;
		}

		@media (--max48) {
			& > * + * {
				margin-top: 0.6rem;
			}
		}
	}

	@element rightpanel {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 213px;

		& button[class*="c-cookies-settings__button--bottom"] {
			position: absolute;
			bottom: 0 !important;
		}
	}

	@element container--fullscreen {
		@media (--min48) {
			top: auto;
			overflow-y: inherit;

			& > [class*="__content"] {
				margin-bottom: 0;
			}
		}
	}

	@element cookietext {
		@media (--min48) {
			padding-right: 4rem;
		}
	}
}

@block c-cookies-settings {
	margin-bottom: 0;

	@media (--min48) {
		display: flex;
	}

	@element content {
		display: none;
	}

	@element button--bottom {
		margin-top: 2rem;

		@media (--min64) {
			position: relative;
		}

		@media (--max48) {
			position: fixed;
			top: auto;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			border-radius: 0;
			padding: 2.4rem;
			margin: 0;
		}
	}

	@element label {
		cursor: pointer;
		display: inline-block;
		padding: 2rem;
		border: 2px solid #a2a2a2;
		color: #000;
		position: relative;
		margin-bottom: 0.5rem;
		width: 100%;
		transition: all 0.1s ease-in-out;
		border-radius: var(--radius);
		box-shadow: 0px 3px 5px color-mod(#000 a(8%));

		& h4 {
			margin-bottom: 1.6rem;
		}

		&:before {
			content: "";
			width: 3rem;
			height: 3rem;
			border-radius: 50%;
			background: white;
			display: inline-block;
			border: 2px solid #a2a2a2;
			position: absolute;
			right: 2rem;
			top: 1.7rem;
		}

		&:after {
			content: "";
			width: 1.8rem;
			height: 1rem;
			border-left: 4px solid var(--blue);
			border-bottom: 4px solid var(--blue);
			display: inline-block;
			opacity: 0;
			transition: all 0.1s ease-in-out;
			transform: rotate(-46deg);
			position: absolute;
			right: 2.6rem;
			top: 2.5rem;
			background: transparent;
		}

		@media (--min48) {
			margin-bottom: 0;
			width: auto;
			padding: 2rem 5rem;
			flex: 1;
			word-break: break-word;

			&:not(:first-of-type) {
				margin-left: 1rem;
			}
		}
	}

	@element explanation {
		font-size: var(--fz-small);
		color: #000;

		& * {
			margin: 0;
		}

		& p {
			margin-bottom: 0.5rem;
		}

		& p:not(:first-of-type) {
			margin-top: 0.5rem;
		}
	}
	@element explanation__list-style--no > li, @element explanation__list-style--yes > li {
		list-style: none;
		position: relative;
		&:before {
			content: "";
			width: 1.3rem;
			height: 1.7rem;
			display: inline-block;
			opacity: 0.7;
			position: absolute;
			top: 0.2rem;
			left: -1.7rem;
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;
			background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxMDI0IiB3aWR0aD0iNjQwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGw9IiMyMjIyMjIiIGQ9Ik02NDAgMzIwTDUxMiAxOTIgMzIwIDM4NCAxMjggMTkyIDAgMzIwbDE5MiAxOTJMMCA3MDRsMTI4IDEyOCAxOTItMTkyIDE5MiAxOTIgMTI4LTEyOEw0NDggNTEyIDY0MCAzMjB6Ii8+PC9zdmc+);
		}
	}

	@element explanation__list-style--yes > li:before {
		background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI1Ljc1NCIgd2lkdGg9IjMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGZpbGw9IiMyMjIyMjIiIGQ9Ik0xMS45NDEgMjUuNzU0TDAgMTMuODEybDUuNjk1LTUuNjk1IDYuMjQ2IDYuMjQ2TDI2LjMwNSAwIDMyIDUuNjk1IDExLjk0MSAyNS43NTR6Ii8+PC9zdmc+);
	}

	@element back {
		font-size: @fz-small;
		cursor: pointer;
	}

	& input {
		display: none;
		opacity: 0;

		@media (--min48) {
			z-index: -1;
			position: absolute;
			display: inline-block;
			opacity: 0;
		}
	}

	& input:checked + label:after {
		opacity: 1;
	}

	& input:focus,
	& input:checked + label {
		border-color: hsl(var(--primary));
	}
}
