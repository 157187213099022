@block l-image {
	@element parent {
		position: relative;
		overflow: hidden;
		/* todo: remove when images work */
		/* background: rgba(0, 0, 0, 0.5); */

		@modifier landscape {
			&::before {
				content: "";
				display: block;
				padding-top: 62.50%;
				aspect-ratio: 16/10;
			}
			& > img {
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		@modifier fourbythree {
			&::before {
				content: "";
				display: block;
				padding-top: 75%;
				aspect-ratio: 4/3;
			}
			& > img {
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		@modifier slider {
			/* &::before {
				aspect-ratio: 16/9;
			} */
			& > img {
				/* object-fit: contain; */
				object-fit: cover;
			}
		}
		@modifier square {
			&::before {
				content: "";
				display: block;
				padding-top: 100%;
			}
			& > img {
				position: absolute;
				top: 0;
				left: 0;
			}
		}
		@modifier border {
			border-radius: var(--radius);
		}
	}

	@element item {
		width: 100%;
		height: 100%;
		opacity: 0;
		@mixin objectfit cover;
		transition: opacity 0.5s cubic-bezier(0.02, 0.61, 0.15, 0.96);

		@modifier can-transition {
			opacity: 0;
			transition: opacity 0.5s cubic-bezier(0.02, 0.61, 0.15, 0.96);
		}
		@modifier loaded {
			opacity: 1;
		}
		@modifier flip-x {
			transform: scaleX(-1);
		}
		@modifier contain {
			@mixin objectfit contain;
		}
	}
	@element object-fit__item {
		width: 100%;
		height: 100%;
		position: relative;
	}
}

picture {
	display: block;
	width: 100%;
}
