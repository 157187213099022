@block c-header {
	@element container {
		position: sticky;
		z-index: var(--z-pageheader);
		top: 0;

		&.scrolled {
			background: #FFF;
		}

		/* @media (--max64) {
			padding-left: 0;
			padding-right: 0;
		}	 */
	}	

	@element inner {
		width: 100%;
		display: grid; 
		grid-template-columns: 10rem 1fr auto;
		grid-template-rows: 1fr;
		box-shadow: 0px 2px 10px hsla(var(--c-body), 0.05);
		background: #fff;

		@media (--max64) {
			grid-template-columns: 1fr 10rem 10rem;

			&:before{
				content: '';
				background: #fff;
				width: 100%;
				height: 100%;
				position: absolute;
				z-index: 51;
				top: 0;
				left: 0;
			}
		}

		@media (--min72) {
			grid-template-columns: 12.5rem 1fr auto;
		}

		@media (--min80) {
			grid-template-columns: 14rem 1fr auto;
		}

		@media (--max80) {
			padding-right: 0;
			padding-left: 0;
		}
	}

	@element logo {
		grid-area: 1 / 1 / 1 / 1;
		position: relative;
		display: flex;
		width: 100%;
		max-width: 12rem;
		text-decoration: none;
		z-index: 52;
		margin-left: 0;
		
		&:focus {
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed hsl(var(--secondary));
			outline-offset: 60px;
		}
		& > img {
			width: 100%;
			max-width: 6rem;
			margin: 0 auto .5rem;
			height: calc(100% - 2rem);
			align-self: end;
		}

		&:after{
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			background: #fff;
			border-radius: 50%;
			bottom: -1.75rem;
			left: -.25rem;
			right: 0;
			margin: auto;
			z-index: -1;
		}

		@media (--min64) {
			max-width: 12rem;
			margin-left: 0;

			& > img {max-width: 6.5rem;}

			&:after {
				bottom: -1.5rem;
				left: -.75rem;
				width: calc(100% + 1.5rem);
				right: 0;
				margin: auto;
			}
		}

		@media (--min72) {
			max-width: 24rem;
			margin-left: 0rem;

			& > img {max-width: 7.5rem;}

			&:after {
				bottom: -1.75rem;
				left: -.75rem;
				width: calc(100% + 1.5rem);
				right: 0;
				margin: auto;
			}

		}

		@media (--min80) {
			margin-left: -2.5rem;

			& > img {max-width: 9rem;}

			&:after {
				bottom: -3rem;
				left: -2.5rem;
				width: calc(100% + 5rem);
				right: 0;
				margin: auto;
			}
		}
	}

	@element button {
		grid-area: 1 / 3 / 1 / 4;
		position: relative;
   		z-index: 52;

		@media (--min64) {
			display: none;
		}

		@modifier back {
			grid-area: none !important;
			position: absolute 3rem auto auto calc(100% + 1.4rem);
		}
	}
}

body.has-modal > .c-header__container{
	z-index: 0;
}