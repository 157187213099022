@font-face {
	font-family: 'Outfit';
	src: url('/Assets/Fonts/Outfit-VariableFont_wght.ttf') format("truetype-variations");
	font-style: normal;
	font-weight: 1 999;
	/* font-display: swap; */
}

:root {
	/* font family */
	--font-base: "Outfit", -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif;
    --font-title: "Outfit", Arial Black, -apple-system, BlinkMacSystemFont, "Segoe UI", Arial, sans-serif;

	/* font weights */
	--fw-body: 400;
	--fw-bold: 700;
	--fw-base: 400;
	--fw-medium: 600;
	--fw-title: var(--fw-bold);
}

:root {	
	--fz-homepage-title: clamp(7rem, calc(6vw + 2rem), 13rem);
	--fz-title: clamp(6.5rem, calc(5vw + 4rem), 11rem);
	--fz-title--sm: clamp(5.5rem, calc(5vw + 3rem), 9rem);
	--fz-subtitle: clamp(5rem, calc(5vw + 2rem), 8rem);
	--fz-h2: clamp(4.2rem, calc(3vw + 1rem), 6.5rem);
	--fz-body: 1.8rem;

	--fz-semi: 2.4rem;
	--fz-linkitem: 3rem; 

	--fz-120: 12rem;
	--fz-100: 10rem;
	--fz-60: 6rem;
	--fz-40: 4rem;
	--fz-30: 3rem;
	--fz-24: 2.4rem;
	--fz-22: 2.2rem;
	--fz-20: 2rem;
	--fz-18: 1.8rem;
	--fz-16: 1.6rem;
	--fz-14: 1.4rem;
	--fz-13: 1.3rem;
	--fz-12: 1.2rem;
	--fz-11: 1.1rem;

	--lh-title: 0.85em;
	--lh-sfeerconcept: 0.85em;

	@media (--max48) {
		--fz-120: 4rem;
		--fz-100: 3.4rem;
		--fz-30: 2.8rem;
	}

	@media (--min120){
		--fz-body: 2.2rem;
	}
}

:root {
	--max-width: 120em;
	/* border */
	--radius: 5px;
	
	--m-xs: 1.6rem;
	--m-small: 2.6rem;
	--m-base: 4rem;
	--m-large: 8rem;
	--m-xl: 10rem;
	--m-grid: 6rem;
	--grid-width: calc((2.4rem * 12) + (11.5 * 11));

	--m-outside: 3rem;
	--m-inside: 10rem;

	--z-base: 10;
	--z-pageheader: 20;
	--z-modal: 100;


	@media (--max48) {
		--m-base: 3rem;
		--m-grid: 3rem;

		--m-outside: 0rem;
		--m-inside: 2.5rem;
	}

	@media (--max64) {
		--m-inside: 3rem;
		--m-xl: 4rem;
	}	
	
	@media (--max80) {
		--m-grid: 4rem;
		--m-large: 6rem;
	}

	@media (--min124){
		--max-width: 100em;
	}
}

:root {
	/* system */
	--green: 120, 100%, 25%;
	--error: 0, 100%, 40%;
	--bg-overlay: hsl(var(--c-body));
	--fg-overlay: hsl(var(--b-body));

	/* alt #F4841C */
	--alt-h: 29;
	--alt-s: 91%;
	--alt-l: 53%;
	--alt: var(--alt-h), var(--alt-s), var(--alt-l);

	/* extra #721F5D */
	--extra-h: 315;
	--extra-s: 57%;
	--extra-l: 28%;
	--extra: var(--extra-h), var(--extra-s), var(--extra-l);

	/* Dark #0C041C */
	--dark-h: 260;
	--dark-s: 75%;
	--dark-l: 6%;
	--dark: var(--dark-h), var(--dark-s), var(--dark-l);

	/* light #EBF4F9 */
	--light-h: 201;
	--light-s: 54%;
	--light-l: 95%;
	--light: var(--light-h), var(--light-s), var(--light-l);		
	
	/* light #F2F2F3 */
	--light-gray-h: 240;
	--light-gray-s: 4%;
	--light-gray-l: 95%;
	--light-gray: var(--light-gray-h), var(--light-gray-s), var(--light-gray-l);	

	/* c-body #0C041C */
	--c-body-h: 260;
	--c-body-s: 75%;
	--c-body-l: 6%;
	--c-body: var(--c-body-h), var(--c-body-s), var(--c-body-l);

	/* b-body #FFFFFF */
	--b-body-h: 0;
	--b-body-s: 0%;
	--b-body-l: 100%;
	--b-body: var(--b-body-h), var(--b-body-s), var(--b-body-l);
	
	/* Pink #E71D73 */
	--pink-h: 334;
	--pink-s: 81%;
	--pink-l: 51%;
	--pink: var(--pink-h), var(--pink-s), var(--pink-l);


	/* Pink dark #AF1858 */
	--pinkdark-h: 335;
	--pinkdark-s: 76%;
	--pinkdark-l: 39%;
	--pinkdark: var(--pinkdark-h), var(--pinkdark-s), var(--pinkdark-l);

}

/* MEDIA QUERIES */

@custom-media --min36 (min-width: 36em);
@custom-media --min48 (min-width: 48em);
@custom-media --min64 (min-width: 64em);
@custom-media --min72 (min-width: 72em);
@custom-media --min80 (min-width: 80em);
@custom-media --min100 (min-width: 100em);
@custom-media --min120 (min-width: 120em);
@custom-media --min124 (min-width: 124em);

@custom-media --max24 (max-width: 24em);
@custom-media --max30 (max-width: 29.999em);
@custom-media --max48 (max-width: 47.999em);
@custom-media --max64 (max-width: 63.999em);
@custom-media --max80 (max-width: 79.999em);
@custom-media --max100 (max-width: 99.999em);

@custom-media --ie (-ms-high-contrast: none), (-ms-high-contrast: active);



/* MIXINS */

@define-mixin transition $element: all, $time: 0.15s, $ease: ease-in-out {
	transition: $element $time $ease;
}
@define-mixin objectfit $prop: cover {
	object-fit: $prop;
	font-family: 'object-fit: $prop;';
}