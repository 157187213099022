@block g-blogitems__v1 {
	@element container {
		margin-bottom: 4rem;
		padding-bottom: 8rem;
	}
	@element grid  {
		
	}
	@element svg-holder {
		position: absolute 0 0 0 0;
	    width: 100vw;
	    height: calc(100% + 4rem);
	    overflow: hidden;
	    z-index: -1;

	    @media(--max48) {
	    	display: none;
	    }
	}
	@element svg {
		fill: hsl(var(--alt));
		width: 100%;
	    position: absolute 5rem auto auto 20%;
	}
}