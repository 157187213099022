@block c-filter-menu{
    display: flex;
    column-gap: var(--m-large);
    row-gap: 1rem;
    align-items: center;
    border-bottom: 1px solid hsla(var(--black-purple), 0.2);
    margin-bottom: var(--m-base);
    flex-wrap: wrap;
    padding-top: 4rem;
    padding-bottom: 4rem;

    @element btn-holder{
        display: flex;
        flex-flow: row wrap;
        gap: var(--m-base);
    }

    @element button{
        position: relative;
        font-size: 1.6rem;
        display: flex;
    }
}