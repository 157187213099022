@block c-category {
	
	@element container {
		z-index: 20;
		margin-top: 0 !important;
	}
	@element scroller {
		opacity: 0;
		transition: opacity .2s ease-out;

		/* &::after {
		    content: "";
			position: absolute 0 -9999px;
		    width: 110vw;
		    background: hsl(var(--primary));
		    display: block;
		    margin: auto;
		    z-index: 0;
		} */
	}
	@element list-holder {
		z-index: 1;
		position: relative;
		padding: 1rem 4rem 1rem 4rem;
		width: calc(100% + 8rem);
	    margin-left: -4rem;
	    overflow: hidden;
	    &::before, &::after {
	    	content: "";
		    width: 4rem;
		    background-image: linear-gradient(90deg, hsl(var(--primary)), hsla(var(--primary)), 0);
		    height: 100%;
		    position: absolute;
		    left: 0;
		    top: 0;
		    z-index: 2;
	    }
	    &::after {
	    	left: auto;
	    	right: 0;
	    	background-image: linear-gradient(-90deg, hsl(var(--primary)), hsla(var(--primary)), 0);
	    }
	    @media (--max48) {
	    	padding-top: 4rem;
	    }
	}

	@element list {
		display: none;
		justify-content: center;
		list-style: none;
		padding: 0;
		margin: 0;
		@media (--min48) {
			display: flex;
		}
	}
	
	@element item {
		padding: 0;
		font-weight: var(--fw-bold);
		white-space: nowrap;
		cursor: pointer;
		&:last-of-type {
			padding-right: 8rem;
		}

		& + * {
			margin-left: .4rem;
		}
		&:hover > [class*="button"] {
			background: rgba(0,0,0,0.1);
			transition: background .15s ease;
		}
		&.s-active > [class*="button"] {
			background: hsl(var(--c-body));
			color: hsl(var(--b-body));
			text-decoration: none;
		}
	}

	@element dropdown {
		display: block;
		& select {
			width: 100%;
			padding: 1rem 2rem;
			border: 1px solid #000;
		    border-radius: calc(var(--radius) * 2);
		    background-color: transparent;
		    height: 5.5rem;
		}
		@media (--min48) {
			display: none;
		}
	}
	
	@element button {
		height: 5rem;
		display: flex;
		align-items: center;
		padding: 1rem 3rem;
		transition: background .12s ease;
		border-radius: 6rem;
		user-select: none;
		background: transparent;
		border: 0;
		appearance: none;
		font-family: inherit;
		font-weight: inherit; 
		font-size: inherit;
		color: inherit;
		text-decoration: underline;
		&:focus {
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed hsl(var(--c-body));
			outline-offset: 0px;
		}
		@media (--max48) {
			padding: 1rem 2rem;
		}
	}
	
}

html[data-theme="lightshow"] {
	& .c-category__scroller {
		&::after {
			opacity: 0;
			transition: opacity .15s ease;
		}
		&.s-snapped::after {
			opacity: 1;
		}
	}
	
}
