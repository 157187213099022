@block c-blog-tile {
	@element grid {
		display: flex;
		flex-wrap: wrap;		
		& > * {
			width: 100%;
			margin-right: 0;
			margin-bottom: 6rem;

			@media (--min48) {
				width: calc((100% - 6rem) / 2);
				margin-right: 6rem;

				&:nth-of-type(2n) {
					margin-right: 0;
				}
			}
			@media (--min80) {
				width: calc((100% - 12rem) / 3);
				margin-right: 6rem;

				&:nth-of-type(2n) {
					margin-right: 6rem;
				}
				&:nth-of-type(3n) {
					margin-right: 0;
				}
			}
			@media (--min100) {
				width: calc((100% - 18rem) / 4);
				margin-right: 6rem;

				&:nth-of-type(2n), &:nth-of-type(3n) {
					margin-right: 6rem;
				}
				&:nth-of-type(4n) {
					margin-right: 0;
				}
			}
		}

		@supports (display:grid) {
			/* autoprefixer: off */
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-template-columns: repeat(auto-fill, minmax(35rem, 1fr));
			grid-template-columns: repeat(auto-fill, minmax(min(35rem, 100%), 1fr));
			grid-gap: 6rem;

			& > * {
				width: auto;
				margin: 0;
				&:nth-of-type(2n), &:nth-of-type(3n), &:nth-of-type(4n) {
					margin: 0;
				}
			}
		}
		@modifier one-row {
			/* autoprefixer grid: off */
			grid-template-rows: repeat(2, 1fr);
			grid-auto-rows: 0; 
			grid-row-gap: 0;
			overflow-y: hidden;
		    margin-left: -2rem;
		    margin-right: -2rem;
		    padding-left: 2rem;
		    padding-right: 2rem;
		    margin-top: -2rem;
		    & > .c-blog-tile__item {
		    	top: 2rem;
		    	margin-bottom: 8rem;
		    }
			@media (--min48) {
				/* autoprefixer grid: off */
				grid-template-rows: repeat(1, 1fr);
				margin-left: -4rem;
			    margin-right: -4rem;
			    padding-left: 4rem;
			    padding-right: 4rem;
			}
		}
	}
	@element item {
		position: relative;
		background: #fff;
		border-radius: calc(var(--radius) * 2);
		padding: 0;
		font-size: var(--fz-18);
		overflow: hidden;
		transition: all 0.1s ease;
		cursor: pointer;
		color: #fff;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		text-decoration: none;

		& > *:not([class*="picture"]) {
			z-index: 5;
		}
		&::before {
			content: "";
		    display: block;
		    padding-top: 150%;
		    float: left;
		}
		
		&:hover {
			& [class*="__blob"] {
				color: hsl(var(--secondary));
				transform: scale(1.8) rotate(100deg);
				transition: color 0.4s ease, transform 0.25s cubic-bezier(0.65, 0.31, 0, 0.95);
			}
			& [class*="__icon"] {
				color: #fff;
				transition: color 0.15s ease;
			}
		}
	}
	@element article {
		padding: 4rem;
	}
	@element title {
		font-size: var(--fz-30);
		line-height: 1.4;
		color: inherit;
	}
	@element date {
		opacity: 0.8;
	    font-weight: 400;
	    margin-top: 0.2rem;
	    display: inline-block;
	}
	@element content {
		position: relative;
		& ul > li + li {
			margin-top: 0.4em/;
		}
	}
	@element more {
		position: absolute auto -18px -23px auto;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	@element icon {
		position: absolute;
		top: 0;
		left: 0;
		right: 8px;
		bottom: 8px;
		margin: auto;
		z-index: 1;
		color: hsl(var(--c-body));
	}
	@element blob {
		color: #fff;
		width: 80px;
		height: 80px;
		transform: rotate(120deg);
		transition: color 0.15s ease, transform 0.2s ease-out;
	}
	@element picture {
	    position: absolute 0 0 0 0;
	    &::after {
	    	content: "";
	    	position: absolute 0 0 0 0;
	    	background: linear-gradient(0deg, rgba(0,0,0,0.95), rgba(0,0,0,0));
	    	z-index: 1;
	    }
	}
}
