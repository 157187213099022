@block cb-slider{
    max-width: 100rem;
    margin: 0 auto;
	position: relative;

    @element item, thumb-outer {
		opacity: 0;
		transition: opacity .3s ease;
		display: none;
		& .l-image__item--loading {
	    	opacity: 1;
	    }
	}

	@element thumb-outer {
		margin-top: 4rem;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (--max48) {
			margin-top: 0;
			top: 40%;
			position: absolute;
			justify-content: space-between;
			width: 90%;
			left: 5%;
		}
	}

	@element thumb {
		& .slick-list {
			width: calc(300px + 6rem);
		}
		& .slick-slide {
			width: 100px;
		    height: 80px;
		    background: hsl(var(--secondary));
		    margin-left: 1rem;
		    margin-right: 1rem;
		    overflow: hidden;
            border-radius: var(--radius);
		    display: flex;
		    align-items: center;
			justify-content: center;
		    position: relative;
		    & > div, & > div > picture {
		    	height: 100%;
				width: 100%;
		    }
		    &::after {
		    	content:"";
		    	position: absolute 0 0 0 0;
		    	display: block;
		    	border: 3px solid rgba(0,0,0,0.05);
		    	transition: border-color .4s .2s ease;
                border-radius: inherit;
		    }
		    &.slick-current::after {
		    	border-color: hsl(var(--primary));
		    }
		}	

		@media (--max48) {
			width: 0;
			overflow: hidden;
			visibility: hidden;
		}
	}
	@element button {
		appearance: none;
		background: transparent;
		border: 0;
		position: relative;
		transition: all .1s ease-in-out;
		&:first-child {
			margin-right: 3rem;
		}
		&:last-child {
			margin-left: 3rem;
		}
		&:hover {
			& [class*="cb-slider__button-bg"] {
				background: hsl(var(--primary));
			}
			
			& [class*="cb-slider__button-front"] {
				color: hsl(var(--white));
			}

			& [class*="button-blob"] {
				color: hsl(var(--secondary));
			}
		}	

		&:focus {
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed hsl(var(--secondary));
			outline-offset: 2px;
		}	
	}
	@element button-bg {
		background: hsl(var(--black-purple));
        border-radius: 50%;
        display: block;
		width: 4rem;
		height: 4rem;
		transition: color .1s ease-in-out;

		@media (--max48){
			background: hsl(var(--white));
		}
	}
	@element button-front {
		position: absolute 0 0 0 0;
		margin: auto;
		z-index: 1;
		color: #fff;

		@media (--max48){
			color: hsl(var(--black-purple));
		}
	}
}