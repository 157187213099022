@block c-pricing{
    display: grid;
    grid-template-areas: "price-label"
                         "price"
                         "label"
                         "buttons";
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    gap: .5rem var(--m-base);

    @media (--max48) {
       grid-template-columns: 1fr;
    }

    @media (--min100) {
        grid-template-areas: "price-label label label"
                             "price buttons buttons";
    }
                        
    @element price {
        grid-area: price;
        font-size: var(--fz-40);
        width: max-content;
    }

    @element buttons {
        grid-area: buttons;
        align-self: start;
    }

    @element label{
        font-weight: var(--fw-bold);
        font-size: 1.6rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        
        @modifier price{ grid-area: price-label; }
        @modifier buttons{ grid-area: label; }

        & > .btn{ 
            display: inline-flex; 
            width: auto;    
        }
    }

    
    @element tiny-label {
        color: hsla(var(--black-purple), 0.75);
        font-size: 1.1rem;
        display: block;
        text-align: right;
        margin-top: .75rem;
    }
}