@block g-tekst-en-afbeelding__v1 {
	@element grid {
		display: grid;
		grid-template-areas: "text" "picture";
		grid-template-columns: 1fr;
		gap: 2.5rem;

		& > [class*="__col--text"] {
			align-self: center;
			grid-area: text;
		}
		& > [class*="__col--picture"] {
			align-self: center;
			grid-area: picture;
		}

		@media (--min48) {
			grid-template-areas: "text picture";
			grid-template-columns: repeat(2, minmax(0, 1fr));
			gap: 6rem;
		}

		@media (--min64) {
			gap: 12rem
		}

	}
}

/* V2 moet gedupliceerd staan, anders snapt IE niets van het grid. */
@block g-tekst-en-afbeelding__v2 {
	@element grid {
		display: grid;
		grid-template-areas: "text" "picture";
		grid-template-columns: 1fr;
		gap: 2.5rem;

		& > [class*="__col--text"] {
			grid-area: text;
			align-self: center;
		}
		& > [class*="__col--picture"] {
			grid-area: picture;
			align-self: center;
		}

		@media (--min48) {
			gap: 6rem;
			grid-template-areas: "picture text";
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		@media (--min64) {
			gap: 12rem
		}

	}
}

/* Styles die voor beide blokken gelden */
@block g-tekst-en-afbeelding__v1, g-tekst-en-afbeelding__v2 {
	@element col {
		& > :--heading {
			color: hsl(var(--primary));
		}
		& > p {
			line-height: 1.5;
			font-weight: 400;
		}
		& > .btn {
			margin-top: 3rem;
		}
	}
}
