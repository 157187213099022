@block c-linkelem {
	@element list {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		& > * + * {
			margin-top: 2rem;
		}
	}
	@element item {
		display: grid;
		grid-template-areas: "arrow title" "arrow text";
		grid-template-columns: auto 1fr;
		grid-template-rows: auto auto;
		grid-row-gap: 1rem;
		grid-column-gap: 1.5rem;
		color: hsl(var(--secondary));
		font-size: var(--fz-body);
		user-select: text;
		text-decoration: none;
		&:focus {
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed currentColor;
			outline-offset: 8px;
		}

		& [class*="__blob"] {
			transform: rotate(-150deg);
		}
		&:nth-of-type(3n + 2) {
			color: hsl(var(--alt));
			& [class*="__blob"] {
				transform: rotate(30deg);
			}
		}
		&:nth-of-type(3n + 3) {
			color: hsl(var(--primary));
			& [class*="__blob"] {
				transform: rotate(120deg);
			}
		}
		&:hover {
			& [class*="__title"]::before {
				width: 0;
				opacity: 0;
			}
		}
		@media (--min48) {
			grid-column-gap: 3.5rem;

		}
	}
	@element arrow {
		width: 8em;
		height: 8em;
		display: flex;
		align-items: center;
		justify-content: center;
		grid-area: arrow;
		color: inherit;
		font-size: 0.5rem;
		position: relative;
		@media (--min48) {
			font-size: 1rem;
		}
	}
	@element blob {
		width: 100%;
		height: 100%;
		color: inherit;
		fill: currentColor;
	}
	@element icon {
		color: #fff;
		position: absolute 0 0 0 0 ;
		margin: auto;
		z-index: 10;
		width: 2em;
		height: 2em;
	}
	@element title {
		grid-area: title;
		display: block;
		font-size: var(--fz-linkitem);
		color: inherit;
		font-weight: 700;
		margin-top: 0.5rem;
		position: relative;
		justify-self: start;

		&::before {
			content: "";
			width: 100%;
			height: .12em;
			display: flex;
			background: currentColor;
			line-height: 0;
			margin: 0;
			margin-top: 0;
			z-index: -1;
			position: absolute;
			bottom: .1em;
			right: 0;
			transition: all .8s ease;

		}
		@media (--min48) {
			margin-top: 1rem;
		}
	}
	@element text {
		grid-area: text;
		display: none;
		font-size: var(--fz-body);
		max-width: 35rem;
		color: hsl(var(--c-body));
		& > * {
			margin: 0;
			& + * {
				margin-top: 2rem;
			}
		}
		@media (--min48) {
			display: block;
		}
	}
}
