@block c-sidenav {
	grid-area: sidenav;

	@element list {
		display: flex;
		flex-direction: column;
		list-style: none;
		padding: 0;
	}
	@element item {
		color: hsl(var(--black-purple));
	}
	@element button {
		color: inherit;
		padding: .8rem 0;
		appearance: none;
		border: 0;
		background: transparent;
		width: 100%;
		text-align: left;
		line-height: 1.5;
		font-weight: 600;
		font-family: var(--font-base);
		font-size: var(--fz-24);

		&:hover,
		&:focus,
		&.s-active {
			color: hsl(var(--primary));

			& .c-sidenav__icon {
				opacity: 1;
				transform: translateX(0rem);
			}
			& .c-sidenav__text {
				transform: translateX(1rem);
			}
		}
	}
	
	@element button-content {
		display: flex;
		align-items: center;
		width: 100%;

		& > * {
			transition: transform 0.4s cubic-bezier(0.15, 0.57, 0.29, 0.88), 
						opacity 0.4s cubic-bezier(0.15, 0.57, 0.29, 0.88),
						color 0.4s ease-out;
		}
	}
	@element text {
		flex: 1 1 auto;
		transform: translateX(-1.6rem);
	}
	@element icon {
		flex: 0 0 auto;
		opacity: 0;
		transform: translateX(-2rem)
	}
	@element label {
		color: hsl(var(--black-purple), 0.5);
		font-weight: 500;
		font-size: 1.7rem;
	}
}