/* BASIC BUTTON ELEMENT */
@block btn {
	position: relative;
	display: inline-flex;
	align-items: center;
	font-family: var(--font-base);
	font-weight: var(--fw-bold);
	font-size: 1.3rem;
	text-align: left;
	white-space: nowrap;
	user-select: none;
	line-height: 1;
	cursor: pointer;
	padding: 1.6rem 2rem;
	color: hsl(var(--b-body));
	background: hsl(var(--secondary));
	border-radius: 50px;
	border: 0;
	max-width: 100%;
	text-overflow: ellipsis;
	overflow: hidden;
	box-shadow: 0px 0px 0px rgba(0,0,0,0);
	flex: 0 0 auto;
	justify-content: center;
	text-transform: uppercase;
	column-gap: 1.3rem; 
	text-decoration: none;
	@mixin transition;
	letter-spacing: .15rem;

	span {font-size: 1.3rem;}

	@media (--max48) {
		font-size: 1.4rem;
		width: 100%;

		span {font-size: 1.4rem;}
	}

	@media (--min64) {
		font-size: 1.3rem;
	}

	&:hover {
		background: hsl(var(--secondary), 0.95);
	}

	&:focus {
		background: hsl(var(--secondary), 0.9);
		outline: 0;
	}
	&:focus-visible {
		outline: 2px dashed hsl(var(--secondary));
		outline-offset: 2px;
	}
	@modifier circ {
		border-radius: 50%;
		padding: 0;
		display: flex;
	    align-items: center;
	    justify-content: center;
	}

	@modifier white {
		background: #fff;
		color: hsl(var(--c-body));
		transition: all 0.25s cubic-bezier(.23,1,.32,1);

		&:after{
			content: '';
			background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMi45NCIgaGVpZ2h0PSIxMS45OTUiIHhtbG5zOnY9Imh0dHBzOi8vdmVjdGEuaW8vbmFubyI+PHBhdGggZD0iTTIyLjIzNyA1LjY0N2gwTDE3LjI2NC42NzRhLjUuNSAwIDAgMC0uODg5LjE5NC41LjUgMCAwIDAgLjE4OS41MDZsNC4xMjMgNC4xMjhIMWEuNS41IDAgMCAwIDAgLjk5NWgxOS42ODVsLTQuMTIzIDQuMTIzYS41LjUgMCAwIDAgLjE5NC44ODkuNS41IDAgMCAwIC41MDYtLjE4OWw0Ljk3My00Ljk3M2EuNS41IDAgMCAwIC4wMDItLjd6IiBmaWxsPSIjMGMwNDFjIiBzdHJva2U9IiMwYzA0MWMiLz48L3N2Zz4=);
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;
			display: inline-block;
			fill: hsl(var(--black-purple));
			width: 1.8rem;
			height: 0.9rem;
			transition: all 0.15s cubic-bezier(.18,.89,.32,1.28);
		}

		&:hover {
			background: #f9f9f9;
			color: hsl(var(--primary));

			&:after{
				transform: translateX(2px); color: hsl(var(--primary));
			}
		}

		&:focus, &:focus-visible {
			background: #fff;
			outline: 2px dashed hsl(var(--primary));
			outline-offset: 2px;
		}

		& .c-ripple__circle {
			background: rgba(0, 0, 0, 0.2);
		}
	}

	@modifier primary {
		background: hsl(var(--primary));
		color: hsl(var(--c-body));
		&:hover {
			background: hsl(var(--primary), 0.95);
		}
		&:focus {
			background: hsl(var(--primary), 0.9);
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed hsl(var(--primary));
			outline-offset: 2px;
		}
	}
	@modifier alt {
		background: hsl(var(--secondary--alt));

		&:hover { 
			background: hsl(var(--primary));
		}
		&:focus {
			background: hsl(var(--primary));
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed hsl(var(--pink));
			outline-offset: 2px;
		}
	}
	@modifier extra {
		background: hsl(var(--extra));
		&:hover {
			background: hsl(var(--extra-h), var(--extra-s), calc(var(--extra-l) - 5%));
		}
		&:focus {
			background: hsl(var(--extra-h), var(--extra-s), calc(var(--extra-l) - 10%));
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed hsl(var(--extra));
			outline-offset: 2px;
		}
	}
	@modifier pink {
		background: hsl(var(--pink));
		&:hover {
			background: hsl(var(--pinkdark));
		}
		&:focus {
			background: hsl(var(--pinkdark));
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed  hsla(var(--pink), 0.7);
			outline-offset: 2px;
		}
	}

	@modifier clear {
		color: hsl(var(--black-purple));
		background: transparent;
		border: 0;
		padding-left: 0;
		padding-right: 0;

		&:hover {
			color: hsl(var(--pink));
		}
		&:focus {
			color: hsl(var(--pink));
			outline: 0;
		}
	}

	@modifier align-left {
		margin-left: auto;
	}

	@modifier autowidth {
		width: auto;
		display: inline-flex;
		&.l-icon__wrap {
			display: inline-flex;
		}
	}
	& > * {
		pointer-events: none;
		z-index: var(--z-base);
		position: relative;
	}
	& > .l-icon__wrap > span {
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 1.2;
	}
	&.s-disabled {
		cursor: not-allowed;
		background: gray;
		opacity: .6;
		&:--enter {
			background: gray;
			cursor: not-allowed;
		}
	}
	
}

@block btn-holder {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;

	@media (--max48) {
		flex-direction: column;
	}
}

@keyframes buttonLoadingAnimation {
	0% {
		transform: translateX(-100%);
	}
	70% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(100%);
	}
}

.c-ripple {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: transparent;
	display: block;
	&.is-active .c-ripple__circle {
		animation: a-ripple 0.35s ease-in;
	}
}

.c-ripple__circle {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	width: 0;
	height: 0;
	border-radius: 50%;
	pointer-events: none;
	background: rgba(255, 255, 255, 0.5);
}

@keyframes a-ripple {
	0% {
		opacity: 0;
	}
	25% {
		opacity: 1;
	}
	100% {
		width: 200%;
		padding-bottom: 200%;
		opacity: 0;
	}
}
