@block c-overlay {
	@element holder {
		display: grid;
		grid-area: 1/1/3/4;
		z-index: 20;
		background: #fff;
		height: 100%;
		max-height: 100vh;
		overflow: auto;
		position: fixed 0 0 0 0;
		overscroll-behavior-y: contain;
		background: var(--fg-overlay);
		color: var(--bg-overlay);

		@modifier dark {
			background: var(--bg-overlay);
			color: var(--fg-overlay);
			@media (--max48) { 
				padding-top: 5rem;
			}

			&:before{
				content: "";
				background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMTYxLjU2MiIgaGVpZ2h0PSIxMTYxLjQ4NCIgeG1sbnM6dj0iaHR0cHM6Ly92ZWN0YS5pby9uYW5vIj48cGF0aCBkPSJNMTQ2LjY0NSA2MzEuMjY4YzEzLjkzNyA0MC40NzYgMzAuMjk2IDgwLjY3NyA0Ny4yNTQgMTIxLjc3NiA0Ny41NTYgMTE1Ljc5NiAxMDEuNDU3IDI0Ny4wNDMgMTg3LjY2MSAyODUuNzY3IDcyLjQ1NiAzMi42ODQgMTg0LjgxMiAxMS4yMDEgMzQzLjQ4Ni02NS42NzUgMTgwLjkxOS04Ny42NTQgMjgwLjcwMS0xNzguMTI2IDMwNS4wNzktMjc2LjY2MyAxMS41NjYtNDYuMjcxIDcuMjUyLTEwMS4wMjQtMTIuNDgzLTE1OC4zMzctMjYuOTU3LTc4LjI4OS04MS40NzgtMTU3LjY5MS0xNDkuNTMzLTIxNy44MTEtMTEzLjY0OC05OS45ODQtMjY1LjQ5Mi0xNDguMDg3LTQxNi42MjgtMTMxLjk3Ni0xNTIuNDIxIDE2LjA3MS0yODYuMTIxIDk1LjMzOC0zMjUuMTY1IDE5Mi44NDMtMjQuNTUzIDYwLjgxNi0xOC4yODIgMTM3Ljk0MiAyMC4zMjkgMjUwLjA3N20tMjguMzY2IDkuNzY3Qzg1LjA5MyA1NDQuNjUyIDY2LjQ1MSA0NDkuMzQgOTguNDk1IDM2OS45NjJjNDUuNDc3LTExMy41NjcgMTk0LjI3MS0xOTUuMDQ1IDM0OS44MzgtMjExLjQ0NyAxNTUuMjYxLTE2LjU1IDMxNy41MjEgMzEuODkyIDQzOS41OTYgMTM5LjI4MyAxMjEuOTIxIDEwNy43MDEgMjA0LjE4OCAyNzQuNDIxIDE3MS4zMDQgNDA1Ljk0NS0zMi41NzUgMTMxLjY3OS0xODAuMzAyIDIyOC4xNjctMzIxLjEgMjk2LjM4N3MtMjc1LjE0NCAxMDguMzI1LTM2OC45MDcgNjYuMDI1Yy05My45OTEtNDIuMjIxLTE0Ny41NTctMTY2LjUzMy0yMDMuMDc5LTMwMS43MTUtMTYuNzI5LTQwLjU0NC0zMy42MzctODIuMDc3LTQ3Ljg2OC0xMjMuNDA2eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
				background-position: 80% 100%;
				background-repeat: no-repeat;
				background-size: 150%;
				position: absolute;
				aspect-ratio: 1/1;
				width: 50%;
				fill: #fff;
				left: 0;
				top: 0;
				opacity: .15;
				z-index: 0;
			}
		}
	}
	@element grid {
		padding: 12rem 22rem;
		display: flex;
		flex-flow: row wrap;

		& > [class*="__content"] {
			margin-right: 16rem;
			flex: 1 1 0px;

			@media (--max64) {
				margin-right: 0;
				flex: 1 1 auto;
			}
		}
		& > [class*="__form"] {
			flex: 1 1 0px;

			@media (--max64) {
				flex: 1 1 auto;
				width: 100%;
			}
		}
		& form {
			width: 100%;
			overflow-x: hidden;
		}

		@supports(display:grid){
			padding: 12rem 0 6rem var(--m-inside);
			display: grid;
			grid-template-columns: 1fr;
			grid-gap: 6rem;
			grid-area: 1/2/1/2;

			& > [class*="__content"] {
				margin-right: 0;
			}

			@media (--min124){
				grid-area: auto;
				margin: 0 auto;
				padding: 12rem var(--m-inside) 6rem;
			}
		}		
		@modifier no-padding {
			padding: 0;
		}
		@modifier offerte {
			/* autoprefixer grid: off */
		    grid-template-columns: repeat(2, minmax(0, 1fr));
		    padding: 0;
			@media (--max64) {
				grid-template-columns: repeat(1, minmax(0, 1fr));
				padding-left: 2rem;
			}
		}
		@media (--max48) {
			padding: 0;
		}
	}
	@element close {
		position: absolute 3rem 2rem auto auto;
		background: transparent;
		color: inherit;
		font-weight: var(--fw-bold);
		font-size: var(--fz-14);
		width: auto;
		text-decoration: underline;
		text-transform: uppercase;
		&:hover, &:focus {
			background: rgba(255,255,255,0.2);
		}

		@media (--max64) {
			top: 1rem;
		}

		@media (--max48) { 
			top: 0; right: 2rem;
		}
	}
}