html, body {
	width: 100%;
	min-height: 100%;
	/* min-height: 100vh; */
	box-sizing: border-box;
	/* overflow: auto; */
}

html.is-locked {
	@media (--min48) {
		position: fixed;
		overflow: hidden;
		max-height: 100%;
		top: 0;
		width: 100%;
	}
	@media (--max48) {
		overflow: hidden;
		padding-right: 17px;
	}
}

main {
	width: 100%;
	/* max-width: 160rem; */
	margin: auto;
	position: relative;
	outline: 0;
	grid-area: mpMain;

	@media (--min48) {
		z-index: var(--z-base);
		margin-top: 0;
	}
}

.blob-gradient-first-stop {
	stop-color: hsl(var(--primary));
}
.blob-gradient-last-stop {
	stop-color: hsl(var(--alt));
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

.vh {
	position: absolute;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}

.skip-link {
	position: absolute;
	top: 2rem;
	left: -9999px;
	z-index: 10001;
	padding: 0.5rem 2rem;
	background: hsl(var(--secondary));
	color: #fff;
	border-radius: 2rem;
	&:focus {
		left: 18rem;
		outline: 0;
	}
	&:focus-visible {
		outline: 2px dashed hsl(var(--secondary));
		outline-offset: 2px;
	}
}

.ta-right {
	text-align: right;
}

.js-inview {
	& > * {
		transform: translateY(4rem);
		opacity: 0;
		transition: all 0.2s ease-in;
		&:nth-child(2n) {
			transition-delay: 0.2s;
		}
	}

	&.s-visible > * {
		transition: all 1s cubic-bezier(0, 0.6, 0.05, 1);
		transform: translateY(0rem);
		opacity: 1;
		&:nth-child(2n) {
			transition-delay: 0.4s;
		}
	}
}

/* Reassign primary color variables to alternative set in theme-chasingsunsets */
.alt-theme{
	--primary: var(--primary--alt);
	--secondary: var(--secondary--alt);
	--themed-text: var(--themed-text--alt);
}
