@block c-usp-block {
	@element container {
		padding: 10rem 2rem;
		@media (--max48) {
			padding: 6rem 2rem;
		}
	}
	@element list {
		display: flex;
		justify-content: center;
		font-size: var(--fz-14);
		font-weight: var(--fw-bold);
		& > * + * {
			margin-left: 6rem;
		}
		@media (--max48) {
			flex-direction: column;
			& > * {
				margin-left: 0;
				margin-bottom: 2rem;
			}
		}
	}
}