@block c-imageoverlay {
	@element parent {
		position: relative;
	}
	@element holder {
		position: absolute 0 0 0 0;
		z-index: 1;
		background: rgba(0, 0, 0, 0.1);
		border-radius: var(--radius);
		display: flex;
		align-items: center;
		justify-content: center;
		transition: background 0.2s ease;
		cursor: pointer;
		&:hover {
			background: rgba(0, 0, 0, 0.3);
			& [class*="__blob"] {
				transform: rotate(-175deg) scale(1.1);
				color: hsl(var(--alt-hs), calc(var(--alt-l) + 5%));
			}
		}
		&.s-playing {
			opacity: 0;
			pointer-events: none;
			visibility: hidden;
		}
	}
	@element button {
		display: flex;
		width: 9rem;
		height: 9rem;
		position: relative;
		top: -1rem;
	}
	@element blob {
		transform: rotate(-165deg);
		color: hsl(var(--alt-hs), var(--alt-l));
		transition: all 0.2s ease;
	}
	@element icon {
		color: #fff;
		position: absolute 0 0 0 0;
		margin: auto;
	}
}
