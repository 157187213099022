@block c-specs {
	font-size: var(--fz-14);
	
	@element block{
		background: hsl(var(--primary));
	} 

	@element title {
		margin-bottom: 2rem;
		font-size: var(--fz-40);
		color: hsl(var(--themed-text--alt));
	}
	@element transport {
		position: relative;
		padding: 2rem 2rem 1rem 2rem;
		background: rgba(0,0,0,0.05);
		& p:first-child, & p:last-child {
			margin: 0;
			padding: 0;
		}
		& svg {
			position: absolute;
		    top: -2.5rem;
		    right: 2rem;
		    max-height: 4rem;
		    max-width: 6rem;
		    fill: var(--c-body);
		}
	}
	& ul {
		margin: 1rem 0 0 0;
		padding: 0;
		list-style: none;
		color: hsl(var(--themed-text));
		& li {
		    padding: 1rem 2rem;
		    position: relative;
		    font-size: var(--fz-14);
		    &:nth-of-type(odd) {
    			background: rgba(0,0,0,0.05);
			}
			&:before {
				content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMC45NTIiIGhlaWdodD0iMTAuOTU0IiB2aWV3Qm94PSIwIDAgMTAuOTUyIDEwLjk1NCI+DQogIDxwYXRoIGlkPSJQYXRoXzExMSIgZGF0YS1uYW1lPSJQYXRoIDExMSIgZD0iTS0xNTguNDA5LTE0Ni41NjRjMS4xMS45NTksMS40NTIsMi45NjMsMS4wMzEsNC43OWE2LjkxNSw2LjkxNSwwLDAsMS0zLjI5NCw0LjQzOGMtMS43Ljk2MS0zLjkwNywxLjI0My01LjI2OS4zNTdzLTEuODgyLTIuOTM0LTIuMTA2LTQuOC0uMTU0LTMuNTQxLjY5MS00LjQzOCwyLjQ2My0xLjAyMiw0LjIxMi0xLjEyNFMtMTU5LjUxNi0xNDcuNTI2LTE1OC40MDktMTQ2LjU2NFoiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDE2OC4xNTYgMTQ3LjM5OCkiIGZpbGw9IiNmOWI4MTYiLz4NCjwvc3ZnPg0K);
				display: inline-block;
				width: 1.2rem;
			    height: 1.2rem;
			    margin-right: 1.2rem;
			}
		}
	}
	& table {
		margin: 0;
	}
}