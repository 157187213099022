@block l-list__checkmarks{
    padding-left: 0; 
    column-count: 2;
    
    & > li{
        list-style: none; 
        font-weight: var(--fw-medium);
    }

    & > li::before{
        content:'';
        display: inline-block;
        transform: rotate(45deg);
        height: 14px;
        width:  9px;
        border-bottom: 2px solid hsl(var(--primary)); 
        border-right: 2px solid hsl(var(--primary));
        margin-right: 2rem;
    }
}