@block c-product-tile {
	@element grid {
		display: flex;
		flex-wrap: wrap;
		position: relative;
		z-index: var(--z-base);
		& > * {
			width: 100%;
			margin-right: 0;
			margin-bottom: 6rem;

			@media (--min48) {
				width: calc((100% - 6rem) / 2);
				margin-right: 6rem;
				&:nth-of-type(2n) {
					margin-right: 0;
				}
			}
			@media (--min80) {
				width: calc((100% - 12rem) / 3);
				margin-right: 6rem;
				&:nth-of-type(2n) {
					margin-right: 6rem;
				}
				&:nth-of-type(3n) {
					margin-right: 0;
				}
			}
			@media (--min100) {
				width: calc((100% - 18rem) / 4);
				margin-right: 6rem;
				&:nth-of-type(2n), &:nth-of-type(3n) {
					margin-right: 6rem;
				}
				&:nth-of-type(4n) {
					margin-right: 0;
				}
			}
		}
		@supports(display:grid) {
			/* autoprefixer: off */
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
			grid-template-columns: repeat(auto-fill, minmax(min(30rem, 100%), 1fr));
			gap: 6rem;
			
			& > * {
				width: auto;
				margin: 0;
				&:nth-of-type(2n), &:nth-of-type(3n), &:nth-of-type(4n) {
					margin: 0;
				}
			}
		}
		& + [class*="__category"] {
			margin-top: 12rem;
		}
		& .c-blog-tile__title {
			font-size: var(--fz-18);
		}
		& .c-blog-tile__item {
			font-size: var(--fz-14);
			min-height: 400px;
			&:hover {
				& [class*="blob"] {
					color: hsl(var(--primary));
					transform: scale(1.8) rotate(230deg);
				}
			}
		}
		& .c-blog-tile__icon {
			color: #fff;
		}
		& .c-blog-tile__blob {
			color: hsl(var(--secondary));
			transform: rotate(210deg);
		}
		@modifier one-row {
			/* autoprefixer grid: off */
			grid-template-rows: repeat(2, 1fr); 
			overflow-y: hidden;
		    margin-left: -2rem;
		    margin-right: -2rem;
		    padding-left: 2rem;
		    padding-right: 2rem;
		    margin-top: -2rem;
		    & > .c-product-tile__item {
		    	margin: 2rem 0;
		    }
			@media (--min48) {
				/* autoprefixer grid: off */
				grid-template-rows: repeat(1, 1fr);
				margin-left: -4rem;
			    margin-right: -4rem;
			    padding-left: 4rem;
			    padding-right: 4rem;
			    grid-auto-rows: 0;
			}
		}
		@modifier blog-overview {
			margin-bottom: 12rem;

		}
	}

	@element category {
		width: 100%;
		font-size: var(--fz-24);
		font-weight: var(--fw-bold);
		text-transform: uppercase;
		margin-bottom: 4rem;
		@media (--max48) {
			margin-bottom: 2rem;
		}
	}

	@element item {
		position: relative;
		background: hsla(var(--yellow-light));
		color: hsl(var(--black-purple));
		border-radius: calc(var(--radius) * 2);
		padding: 0 2rem 2rem;
		font-size: var(--fz-12);
		overflow: hidden;
		transition: all 0.1s ease;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.08);
		transition: box-shadow 0.25s cubic-bezier(0.65, 0.31, 0, 0.95);

		&::before {
			content: "";
			width: 100%;
			height: 100%;
			position: absolute 0 0 0 0;
			border-radius: inherit;
			border: 1px solid rgba(0, 0, 0, 0.06);
			opacity: 0;
			z-index: -1;
			transition: opacity 0.25s ease-in-out;
		}

		&:hover {
			/*box-shadow: 0px 10px 32px 7px rgba(0, 0, 0, 0.08);*/
			&::before {
				opacity: 1;
			}
			& [class*="blob"] {
				color: hsl(var(--primary));
				transform: scale(1.3) rotate(40deg);
				/* transition: color 0.2s ease, transform 0.25s cubic-bezier(0.65, 0.31, 0, 0.95); */
			}
		}

		&:focus-within {
			outline: 2px dashed red;
			outline-offset: 6px;
		}
		@media (--min48) {
			padding: 0 3rem 3rem;
			font-size: var(--fz-14);
		}
	}

	@element header {
		margin-top: 2rem;
	}
	@element title {
		font-size: var(--fz-24);
		color: hsl(var(--yellow));
	}
	@element content {
		flex: 1 0 auto;
    	overflow: hidden;
		& ul > li + li {
			margin-top: 0.4em;
		}

		& li::marker{	
			color: hsl(var(--yellow));
		}
	}
	@element sub {
		font-weight: var(--fw-bold);
		font-size: var(--fz-16);

		& > p { margin: 0.6rem 0; } 
	}
	@element footer {
		font-weight: var(--fw-bold);
		font-size: var(--fz-18);
	}

	@element link {
		&::before {
			content: "";
			display: block;
			position: absolute 0 0 0 0;
		}
	}

	@element more {
		color: hsl(var(--black-purple));
		position: absolute auto -18px -23px auto;
		display: flex;
		align-items: center;
		justify-content: center;

		&:focus {
			outline: 0;
			outline-offset: 0;
			
		}
	}

	@element icon {
		position: absolute;
		top: 0;
		left: 0;
		right: 8px;
		bottom: 8px;
		margin: auto;
		z-index: 1;
		color: #fff;
	}
	@element blob {
		width: 80px;
		height: 80px;
		/* transform: rotate(210deg); */
		transition: color 0.15s ease-in, transform 0.25s cubic-bezier(0.65, 0.31, 0, 0.95);
	}
	@element picture {
		height: 140px;
		margin-top: 0;
		width: calc(100% + 4rem);
		margin-left: -2rem;

		@media (--min48) {
			height: 180px;
			width: calc(100% + 6rem);
			margin-left: -3rem;
		}
	}
}

html[data-theme="lightshow"] {
	& .c-product-tile__item {
		color: hsl(var(--b-body));
	}
	& .c-product-tile__icon {
		color: hsl(var(--b-body));
	}
	& .c-product-tile__link:hover .c-product-tile__icon {
		color: hsl(var(--c-body));
	}
}
