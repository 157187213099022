@block g-header-rental__v1 {
	@element grid {
		margin-top: 6rem;
		display: grid;
		grid-template-areas: "content search";
		grid-template-columns: 1fr minmax(auto, 40%);
		grid-column-gap: 6rem;
		grid-row-gap: 3rem;
		padding-bottom: 6rem;

		& > [class*="__content-holder"] {
			grid-area: content;
		}
		& > [class*="__search"] {
			grid-area: search;
			min-width: 34rem;
		}
		@media (--max48) {
			margin-top: 3rem;
			padding-bottom: 0;
			grid-template-areas: "content" "search";
			grid-template-columns: repeat(1, minmax(0, 1fr));
		}
	}
	@element backlink {
		grid-area: terug;
	}
	@element svg-holder {
		position: absolute 0 -1000px -6rem -1000px;
		z-index: -1;
	}
	@element svg {
		opacity: 0;
		width: unset;
		position: absolute 0 0 0 0;
		fill: hsl(var(--primary));
		margin: auto;
		min-height: 160vh;
	}
	@element label {
		font-weight: var(--fw-bold);
		font-size: var(--fz-24);
		@media (--max48) {
			display: none;
		}
	}
	@element input {
		height: 8rem;
		border-radius: 8rem;
		padding-left: 4rem;
		padding-right: 4rem;
		box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
		outline: 0;
		&:focus, &:focus-visible {
			outline: 0;
		}
		@media (--max48) {
			height: 6rem;
		}
	}
	@element input-holder {
		position: relative;
		&::after {
			content: "";
			width: calc(100% - 16rem);
			border-bottom: 2px solid hsl(var(--c-body));
			opacity: 0;
			transform: translateY(5px);
			position: absolute auto auto 15px 4rem;
			transition: opacity .1s ease;
		}
		&:focus-within::after {
			opacity: 1;
			transform: translateY(0px);
			transition: transform .4s ease-out, opacity .6s ease;
		}
		@media (--max48) {
			margin-top: 0;
		}
	}
	@element submit {
		position: absolute 0 0 0 auto;
		z-index: 1;
		height: 100%;
		padding: 0 3rem;
		border: 0;
		background: transparent;
		&:focus {
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed hsl(var(--secondary));
			outline-offset: 8px;
		}
	}
	@element title {
		opacity: 0;
	}	
}

html[data-theme="lightshow"] {
	& .g-header-rental__v1__svg {
		fill: url(#blobHeader);
	}
}