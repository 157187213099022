@block c-offerteblob {
	@element container {
		position: fixed 0 0 auto auto;
		z-index: 50;
		@media (--max48) {
			right: 10rem;
			top: 1.4rem;
		}
	}
	@element item {
		-webkit-appearance: none;
		border: 0;
		background: transparent;
		padding: 2.4rem;
		color: hsl(var(--b-body));
		& > span {
			z-index: 10;
			position: relative;
		}
		&:focus {
			outline: 0;
		}
		&::after {
			content: "";
			width: 50px;
			height: 50px;
			border-radius: 50%;
			background: hsl(var(--secondary));
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			margin: auto;
			transform: scale(0);
			z-index: 0;
			opacity: 0;
		}
		@media (--max48) {
			color: hsl(var(--c-body));
			padding: 2.2rem;
		}
	}
	@element blob {
		position: absolute -64% -52% auto auto;
		width: 200%;
		height: 200%;
		transform: rotate(-55deg);
		fill: hsl(var(--secondary));
		transition: opacity 0.15s ease-out;
		z-index: 2;
		@media (--max48) {
			top: -40%;
    		right: -33%;
    		width: 170%;
    		height: 170%;
			transform: rotate(-90deg);
			display: none;
			pointer-events: none;
		}
	}
	@element toaster {
		background: hsl(var(--c-body));
		width: 4rem;
		height: 4rem;
		z-index: 100;
		position: relative;
		border-radius: 50%;
		position: absolute 0 100% 0 auto;
		margin: auto;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
		opacity: 0;
		@media (--max48) {
			right: 80%;
    		top: -5rem;
		}
	}
	@element counter {
		background: hsl(var(--primary));
		width: 2.5rem;
		height: 2.5rem;
		z-index: 101;
		position: relative;
		border-radius: 50%;
		position: absolute 1rem 2rem auto auto;
		margin: auto;
		color: hsl(var(--c-body));
		font-size: var(--fz-14);
		font-weight: bold;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: 1;
		opacity: 0;
		@media (--max48) {
			right: 2.5rem;
			top: .5rem;
		}
	}
}
