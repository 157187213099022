@block g-header-content__v1, g-header-content__v2 {
	
	@element content-holder {
		& header strong {
			font-size: var(--fz-24);
		}
		& p {
			line-height: 1.9;
			font-weight: 400;
		}
		& .btn-holder {
			margin-top: 3rem;
			opacity: 0;
		}
		& .rte {
			opacity: 0;
		}
	}
	/* todo: refactor naar losse component css */
	@element slider-item, slider-thumb-outer {
		opacity: 0;
		transition: opacity .3s ease;
		display: none;
		& .l-image__item--loading {
	    	opacity: 1;
	    }
	}
	@element slider-thumb-outer {
		margin-top: 4rem;
		display: flex;
		justify-content: center;
		align-items: center;
		@media (--max48) {
			display: none !important;
		}
	}
	@element slider {
		& .g-header-content__v1__image {
			height: 100vw;
			@media (--min48) {
				height: 50vw;
				& img {
					object-fit: contain;
					font-family: 'object-fit: contain;';
				}
			}
			@media (--min64) {
				height: 40vw;
			}
			@media (--min80) {
				height: 30vw;
			}
		}
	}
	@element image {
		@modifier sfeerconcept {
			@media (--min48) {
				& img {
					object-fit: cover;
					font-family: 'object-fit: cover;';
				}
			}
		}
	}
	@element slider-thumb {
		& .slick-list {
			width: calc(300px + 6rem);
		}
		& .slick-slide {
			width: 100px;
		    height: 100px;
		    background: hsl(var(--light));
		    margin-left: 1rem;
		    margin-right: 1rem;
		    border-radius: var(--radius);
		    overflow: hidden;
		    display: flex;
		    align-items: center;
		    position: relative;
		    & > div, & > div > picture {
		    	height: 100%;
		    }
		    &::after {
		    	content:"";
		    	position: absolute 0 0 0 0;
		    	display: block;
		    	border: 3px solid rgba(0,0,0,0.05);
		    	border-radius: inherit;
		    	transition: border-color .4s .2s ease;
		    }
		    &.slick-current::after {
		    	border-color: hsl(var(--secondary));
		    }
		}	

	}
	@element slider-button {
		appearance: none;
		background: transparent;
		border: 0;
		position: relative;
		&:first-child {
			margin-right: 3rem;
		}
		&:last-child {
			margin-left: 3rem;
		}
		&:hover {
			& [class*="slider-button-blob"] {
				color: hsl(var(--secondary));
			}
		}	
		&:focus {
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed hsl(var(--secondary));
			outline-offset: 2px;
		}	
	}
	@element slider-button-blob {
		color: hsl(var(--c-body));
		width: 6rem;
		height: 6rem;
		transition: color .1s ease-in-out;
	}
	@element slider-button-front {
		position: absolute 0 0 0 0;
		margin: auto;
		z-index: 1;
		color: #fff;
	}
	/* todo: einde todo */
	
	@element svg-holder {
		position: absolute -8rem -20vw 55rem -26vw;
	    z-index: -1;
	    @media (--min48) {
	    	position: absolute -8rem -20vw -6rem -26vw;
	    }
	}
	@element svg {
		position: absolute 0 0 0 0;
	    fill: hsl(var(--primary));
	    opacity: 0;
	    &:not(:root) {
	    	overflow: visible;
	    }
		@media (--min48) {
			position: absolute auto auto 0 -34vw;
		}
		@media (--max48) {
			min-height: 100%;
		}
	}
	@element title, text, subtitle, image {
		opacity: 0;
	}
}

@block g-header-content__v1 {
	@element grid {
		display: grid;
		grid-template-areas:"terug terug" "content slider";
		grid-template-columns: 40% minmax(0, 1fr);
		
		grid-column-gap: 12rem;
		grid-row-gap: 3rem;
		margin-top: 6rem;
		& > [class*="__slider"] {
			grid-area: slider;
			align-self: center;
		}
		& > [class*="__backlink"] {
			grid-area: terug;
			justify-self: start;
		}
		& > [class*="__content-holder"] {
			grid-area: content;
		}
		& > [class*="__image"] {
			grid-area: slider;
		}
		@media (--max48) {
			grid-template-areas: "slider" "content";
   			grid-template-columns: minmax(0, 1fr);
   			margin-top: 2rem;
   			grid-row-gap: 6rem;
		}
	}
	@element backlink {
		color: hsl(var(--c-body));
		font-weight: 700;
		transition: color .2s ease-out;
		&:hover {
			color: hsl(var(--secondary));
		}
		&:focus {
			outline: 0;
		}
		&:focus-visible {
			outline: 2px dashed hsl(var(--secondary));
			outline-offset: 4px;
		}
		@media (--max48) {
			display: none;
		}
	}
}
@block g-header-content__v2 {
	@element grid {
		display: grid;
		grid-template-areas:"content";
		grid-row-gap: 3rem;
		margin-top: 6rem;
		padding-bottom: 12rem;
		& > [class*="__content-holder"] {
			grid-area: content;
		}
	}
}

html[data-theme="lightshow"] {
	& .g-header-content__v1__svg, & .g-header-content__v2__svg {
		fill: url(#blobGradient);
	}
	& .g-header-content__v1__slider-button-blob, & .g-header-content__v2__slider-button-blob {
		color: hsl(var(--light));
	}
}