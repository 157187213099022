.pplx-lightbox__container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999;
	opacity: 0;
	display: none;
}
.pplx-lightbox__scroll-container {
	position: relative;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	z-index: 10;
	width: 100%;
	height: 100%;
}
.pplx-lightbox__item {
	position: relative;
	max-width: 560px;
	width: 100%;
	background: #fff;
	color: #000;
	border-radius: 10px;
	margin: 70px auto 60px;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}
.pplx-lightbox__item--video {
	max-width: 1260px;
}
.pplx-lightbox__item--video .pplx-lightbox__close {
	background: rgba(255, 255, 255, 0.4);
}
@media (max-width: 47.999em) {
	.pplx-lightbox__item--video .pplx-lightbox__close {
		top: -56px;
	}
}
.pplx-lightbox__item--video .pplx-lightbox__close:hover, .pplx-lightbox__item--video .pplx-lightbox__close:focus { 
	background: rgba(255, 255, 255, 0.9);
	color: #000;
}
.pplx-lightbox__videoframe {
	width: 100%;
	position: relative;
	max-height: 82vh;
	min-height: 400px;
    overflow: hidden;
}
.pplx-lightbox__videoframe::before {
	content: "";
    padding-top: 56.25%;
    display: block;
    width: 100%;
    position: relative;
}
.pplx-lightbox__videoframe > iframe {
	width: 100%;
	height: 100%;
	top: 0;
	position: absolute;
	left: 0;
}
.pplx-lightbox__header {
	background: linear-gradient(50deg, #fa7a00 25%, #e10057);
	color: #fff;
	padding: 40px 80px 20px;
	border-radius: 10px 10px 0 0;
	position: relative;
	top: -10px;
}
.pplx-lightbox__header + article {
	padding-top: 40px;
}
@media (min-width: 48em) {
	.pplx-lightbox__header {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}
}
@media (max-width: 47.999em) {
	.pplx-lightbox__header {
		padding-left: 60px;
		padding-right: 60px;
	}
}
.pplx-lightbox__content {
	padding: 70px 80px 60px;
}
@media (max-width: 47.999em) {
	.pplx-lightbox__content {
		padding-left: 60px;
		padding-right: 60px;
	}
}
.pplx-lightbox__close {
	position: absolute;
	top: 20px;
	right: 20px;
	bottom: auto;
	left: auto;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #2d1d3f;
	z-index: 10;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 0;
	transition: all 0.2s ease-in-out;
}
.pplx-lightbox__close > * {
	transition: all 0.2s ease-in-out;
}
.pplx-lightbox__close:hover,
.pplx-lightbox__close:focus {
	background: rgba(45, 29, 63, 0.5);
}
.pplx-lightbox__close:hover > *,
.pplx-lightbox__close:focus > * {
	transform: scale(1.1);
}
.pplx-lightbox__background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8);
}

.vh {
	position: absolute !important;
	height: 1px !important;
	width: 1px !important;
	overflow: hidden !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
}
